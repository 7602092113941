import React, { FC } from 'react';
import clsx from 'clsx';

import { InputType } from './Input.types';

import styles from './Input.module.scss';

export const Input: FC<InputType> = ({
  type,
  name,
  label,
  placeholder,
  iconLeft,
  iconRight,
  className,
  error,
  background = '',
  value,
  onBlur,
  onChange,
  ...props
}) => {
  return (
    <div className={clsx(styles.block, className, error && styles.error)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}

      <div
        //TODO write complete prop types
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={clsx(styles.inputWrapper, props.disabled ? styles.inputWrapperDisabled : undefined)}
        style={background ? { backgroundColor: `${background}`, border: 'none' } : {}}
      >
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
        <input
          id={name}
          type={type}
          className={styles.input}
          placeholder={placeholder}
          style={{ backgroundColor: `${background}` }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
        {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
      </div>
    </div>
  );
};
