export const getCurrentDate = (separator = '.') => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
};

export const getTimezonelessDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};
export const getTime = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const newDate = new Date(date.getTime() + userTimezoneOffset);
  const h = newDate.getHours();
  const m = newDate.getMinutes();
  return `${h < 10 ? `0${h}` : `${h}`}:${m < 10 ? `0${m}` : `${m}`}`;
};
export const getTimeTzNoLess = (date: Date) => {
  const newDate = new Date(date.getTime());
  const h = newDate.getHours();
  const m = newDate.getMinutes();
  return `${h < 10 ? `0${h}` : `${h}`}:${m < 10 ? `0${m}` : `${m}`}`;
};

export const getDate = (date: Date, separator = '.') => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? `0${day}` : `${day}`}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
};

// прибавление кол-ва дней к конкретной дате
export const addDaysToDate = (date: Date | null, numDays: number): Date | null => {
  if (date) {
    const newDate = new Date(date.getTime() + numDays * 24 * 60 * 60 * 1000);
    return newDate;
  } else {
    return null;
  }
};

export const datediff = (date1: Date, date2: Date) => {
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // Calculating the no. of days between
  // two dates
  const Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  return Difference_In_Days;
};

export const getDateForFilter = (date: Date) => {
  const updDate = getDate(date);
  const arr = updDate.split('.');
  return {
    start: `${arr[2]}-${arr[1]}-${arr[0]} 00:00`,
    end: `${arr[2]}-${arr[1]}-${arr[0]} 23:59`,
  };
};
