import { IPassDates, IPassPlacements } from 'pages/Orders/Order.types';
import axiosInstance from '../axiosInstance';
import { OrderActionResponse } from '../orderActions/OrderActinResponse.model';

export interface addOrderData {
  contact: string | null;
  contact_person_full_name?: string;
  manager?: string | null;
  status?: string;
  payed_status?: string;
  return_summ?: string;
  order_notifications?: orderNotsForCreate[];
  tour?: string;
  tour_date?: string | null;
  children_count?: number;
  adults_count?: number;
  tour_type?: string;
  base_price?: string;
  base_code?: string;
  is_individual?: boolean;
  is_foreign_passport?: boolean;
  is_cruise?: boolean;
  is_hotel?: boolean;
  tour_name?: string;
  tour_date_start?: string;
  tour_date_end?: string;
  tourists?: (string | undefined)[];
  placements?: OrderPlacements[];
  order_additional_nights?: OrderExtraNights[];
  services?: OrderServices[];
  sales_array?: OrderSale[];
  order_transfers?: OrderTransfer[];
  transportation?: string;
  base_summ?: string;
  services_summ?: string;
  additional_nights_summ?: string;
  sales_summ?: string;
  manager_sale?: string;
  total_summ?: string;
  total_amount?: string;
  agent_reward?: string;
  provider_price?: string;
  manager_sale_percent?: string;
  real_payments?: RealPayment[];
  planned_payments?: PlannedPayment[];
  payer?: string;
  show_akvarel?: boolean;
  order_consultation?: IOrderConsultation | null;
  exchange_rate?: string | null;
}

export interface PlannedPayment {
  id: number;
  type: string;
  summ: string;
  plan_date: Date | string;
  exchange_rate: string;
  summ_rub: string;
}

export interface RealPayment {
  id: number;
  method: string;
  date: Date | string;
  summ: string;
  exchange_rate: string;
  summ_rub: string;
}
export interface OrderTransfer {
  start_place: string;
  plan: string;
  start_date: string;
  start_time?: string;
  transport_type: string;
  transport_form: string;
  places_count: number;
  price: string;
}

export interface OrderPlacements {
  id?: string | null;
  hotel_string: string;
  period: string;
  nights_count: number;
  room_type_string: string;
  room_category_string: string;
  placement_type: number;
  food_type: string;
  date_start: string | null;
  date_end: string | null;
  address: string;
  boat_string: string;
  room_level_string: string;
  room_number: string;
  price: string;
  tourists: (string | undefined)[];
}

export interface OrderSale {
  name: string;
  sale_value: string;
  quantity: number;
}

export interface OrderServices {
  name: string;
  price: string;
  quantity: number;
  summ: string;
  description: string;
}

export interface OrderExtraNights {
  id?: string | null;
  date_start?: string | null;
  date_end?: string | null;
  hotel_string: string;
  room_type_string: string;
  room_category_string: string;
  placement_type: number;
  food_type: string;
  price: string;
  tourists: (string | undefined)[];
  comission: string;
}

export interface orderNotsForCreate {
  text: string;
  notify_at: string | Date | null;
}

export interface orderNotification {
  '@id': string;
  id: number;
  text: string;
  notify_at: string;
  is_completed: boolean;
}

export interface OrderTour {
  '@id': string;
  id: number;
  name: string;
  short_description: string;
}
export interface BookingStatus {
  percent: number;
  status: string;
}
export interface Order {
  '@id'?: string;
  id?: number;
  isShared: boolean;
  chat: string;
  countUnread: number;
  contact_person_full_name: string;
  contract_date: string;
  contract_number: string;
  contract_sum: string;
  chat_message: {
    author: string;
    created_at: string;
    is_read: boolean;
    files: string[];
    text: string;
  }[];
  contact: {
    id: number;
    first_name?: string;
    middle_name?: string;
    second_name?: string;
    full_name?: string;
    subject?: string;
    main_document?: any;
    company?: string;
    contact_full_name?: string;
    phone?: string;
    email?: string;
    requisites: any[];
    contacts_array: {
      name: string;
      phone: string;
      email: string;
    }[];
    user: {
      '@id': string;
    } | null;
  };
  tour_operator_contact: {
    '@context': string;
    '@id': string;
    '@type': string;
    id: number;
    full_name: string;
    requisites: {
      '@id': string;
      id: number;
      juri_name: string;
    }[];
  };
  manager: {
    id: number;
    email: string;
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
  };
  status: {
    value: string;
    ordering: number;
  };
  payed_status: string;
  tour?: OrderTour | null;
  tour_date?: string;
  base_code: string;
  exchange_rate: string;
  reserve_system_name: string;
  is_individual: boolean;
  is_foreign_passport: boolean;
  is_hotel: boolean;
  is_cruise: boolean;
  tour_type: string;
  show_akvarel: boolean;
  show_in_pass: boolean;
  pass_placements_array: {
    period: Date[] | null[] | string[];
    period_start: Date | null | string;
    period_end: Date | null | string;
    country: string;
    city: string;
    region: string;
    hotel: string;
    place_type: string;
    tourists: string[];
  }[];
  pass_tour_dates_array: IPassDates[];
  tourists: {
    id: number;
    '@id'?: string;
    full_name: string | null;
    birthday: string | null;
    phone: string | null;
    contact_passport: { code: string } | null;
  }[];
  adults_count: number;
  children_count: number;
  return_summ: string;
  tour_date_start: string;
  tour_date_end: string;
  order_notifications: orderNotification[];
  order_actions: OrderActionResponse[];
  tour_name: string | null;
  total_summ: string | null;
  placements: OrderPlacements[];
  order_additional_nights: OrderExtraNights[];
  services_array: OrderServices[];
  sales_array: OrderSale[];
  order_transfers: OrderTransfer[];
  transportation: string;
  base_summ: string;
  base_price: string;
  services_summ: string;
  additional_nights_summ: string;
  sales_summ: string;
  manager_sale: string;
  manager_sale_percent: string;
  provider_price: string;
  total_amount: string;
  agent_reward: string;
  real_payments: RealPayment[];
  planned_payments: PlannedPayment[];
  order_documents: {
    id: number;
    title: string;
    type: string;
    created_at: Date | string;
    link: string;
    sent_by_email: string | null;
  }[];
  payment_links: {
    uuid: string;
    payment_id: string;
    payment_url: string;
    payment_type: string;
    payment_method: string;
    q_r_code_image_data: string;
    amount: string;
    created_at: string;
    active_before: string;
    last_check: string;
    status: string;
    status_code: string;
  }[];
  payer?: {
    id?: number;
    full_name?: string;
    email?: string;
    responsible?: string;
    order?: string;
  };
  site_request: null | ISiteRequest;
  order_consultation: null | IOrderConsultation | string;
}
export interface ISiteRequest {
  form_type: string;
  full_name?: string;
  phone: string;
  email: string;
  country: string;
  location: string;
  resort_type: string;
  additional_info: string;
  date_from: string;
  date_to: string;
  days_count: number;
  adults_count: number;
  children_count: number;
  call_date: string;
  call_time_from: string;
  call_time_to: string;
  comment: string;
  tour_name: string;
  place_from: string;
  place_to: string;
  start_date: string;
  start_time: string;
  places_count: number;
  back_transfer: boolean;
  created_at: string;
}

export interface IOrderConsultation {
  id?: number;
  dates: string;
  place: string;
  tourists: string;
  hotel: string;
  room_category: string;
  transfer: string;
  transfer_service: string;
  documents_service: string;
  additional_service: string;
  price: string;
  variants_count: string | number;
  sum: string;
}
export interface OrderData {
  page?: number;
  manager?: string | null;
  status?: string | null;
  search?: string | null;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  sortById?: 'asc' | 'desc' | null;
  sortByPartner?: 'asc' | 'desc' | null;
  sortbyData?: 'asc' | 'desc' | null;
  sortbyStatus?: 'asc' | 'desc' | null;
  sortbyTotalSum?: 'asc' | 'desc' | null;
  itemsPerPage?: number;
  tour_type?: string | null;
  isConsultations?: boolean | null;
}

export interface OrdersDataRes {
  'hydra:member': Order[];
}

export const addOrderAPI = async (data?: addOrderData) => {
  const response = await axiosInstance.post<any>('/api/v1/orders', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const updateOrderAPI = async (data: Partial<Order>) => {
  const { id = 0, ...orders } = data;
  if (!id) throw new Error('Missing id while order update');
  const response = await axiosInstance.put<any>(`/api/v1/orders/${id}`, orders, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const ordersAPI = async ({
  page,
  itemsPerPage,
  manager,
  status,
  dateFrom,
  dateTo,
  search,
  sortById,
  sortByPartner,
  sortbyData,
  sortbyStatus,
  sortbyTotalSum,
  tour_type,
  isConsultations,
}: OrderData) => {
  const response = await axiosInstance.get<OrdersDataRes>('/api/v1/orders', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      manager: manager,
      status: status,
      'created_at[before]': dateFrom,
      'created_at[after]': dateTo,
      'sort[id]': sortById,
      'sort[contact.full_name]': sortByPartner,
      'sort[created_at]': sortbyData,
      'sort[status.ordering]': sortbyStatus,
      'sort[total_summ]': sortbyTotalSum,
      search: search,
      tour_type: tour_type,
      'exists[order_consultation]': isConsultations,
      nocache: true,
    },
  });
  return response;
};

export const orderAPI = async (id: number) => {
  const response = await axiosInstance.get<Order>(`/api/v1/orders/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
export const getRealPayments = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/real-payments`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getPlannedPayments = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/planned-payments`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getOrderAdditionalNight = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/order-additional-nights`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getOrderNotifications = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/order-notifications`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getOrderPlacements = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/order-person-placements`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getOrderTransfer = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/order-transfers`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getOrderDocument = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/order-documents`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getOrderConsultations = async (id: number) => {
  const response = await axiosInstance.get<IOrderConsultation>(`/api/v1/order-consultations/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const getBookingStatusAPI = async (id: number) => {
  const response = await axiosInstance.get<BookingStatus>(`/api/v1/order/${id}/check-status`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const getVoucherAPI = async (id: number) => {
  const response = await axiosInstance.get(`/api/v1/order/${id}/voucher`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const getBookingCancelAPI = async (id: number) => {
  const response = await axiosInstance.get(`/api/v1/order/${id}/cancel`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
