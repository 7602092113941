import axiosInstance from '../axiosInstance';

import {
  AllToursResponse,
  IParamsOrder,
  TourDocumentResponse,
  TourHotelResponse,
  TourParams,
  TourRealShortResponse,
  TourResponse,
  ToursResponse,
} from 'services/tours/ToursResponse.model';
import { getTimezonelessDate } from '../../core/utils/getDate';
import { TableDescColumn } from 'components/AddDescription/AddService.type';

export interface ToursData {
  page?: number;
  manager?: string | null;
  tour_directions?: string | null;
  status?: string | null;
  search?: string | null;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  sortById?: 'asc' | 'desc' | null;
  sortByName?: 'asc' | 'desc' | null;
  itemsPerPage?: number;
  tour_type?: string;
  is_visible?: boolean | null;
}

export interface AllToursData {
  page?: number;
  manager?: string | null;
  search?: string | null;
  sortById?: 'asc' | 'desc' | null;
  sortByName?: 'asc' | 'desc' | null;
  itemsPerPage?: number;
  properties?: any[];
  tour_directions?: string | null;
  tour_type?: string;
  is_visible?: boolean | null;
}

export interface ProgramTourDTO {
  id?: string | null;
  description?: string;
  name?: string;
}

export interface TourServicesDTO {
  name?: string;
  price?: number;
  icon?: string;
  siteView?: boolean;
}

export interface TourDiscountsDTO {
  name: string;
  sale_percent?: string;
  sale_value: string;
  years_from?: number;
  years_to?: number;
  sex?: string;
}

export interface GetTourData {
  id: string;
  signal?: AbortSignal;
}

export interface PlacementDTO {
  // id: 0;
  '@id'?: string;
  id?: string;
  price1?: string;
  price2?: string;
  price3?: string;
  price4?: string;
  price5?: string;
  price6?: string;
  price7?: string;
  hotel_string?: string;
  food_type_string?: string;
  places_count?: number | null;
  room_type_string?: string;
  room_category_string?: string;
  placement_type_string?: string;
  start?: string;
  ending?: string;
  show_no_places?: boolean;
}

export interface TourDatesDTO {
  id?: string | null;
  start?: string | Date | null;
  ending?: Date | null;
  base_price?: string | number | null;
  vacant_places_count?: string | number | null;
  sale_percent?: string | number | null;
  sale?: string | number | null;
  agents_sale?: string | number | null;
  agents_sale_percent?: string | number | null;
  status?: string; //active/archive
  placements?: PlacementDTO[];
  tour_additional_nights?: PlacementDTO[];
}
export interface TourHotelsDTO {
  id?: string | null;
  name?: string;
  address?: string;
  distance?: string;
  sale?: string | null;
  sale_percent?: string | null;
  agents_sale?: string | number | null;
  agents_sale_percent?: string | number | null;
  tour_hotel_placements?: PlacementDTO[];
}

// добавление тура - данные для отправки на сервер
export interface AddTourData {
  //данные из вкладки "Описание тура"
  name?: string;
  template?: string;
  duration?: number;
  currency?: string;
  meta_title: string;
  meta_description: string;
  commission?: number | string;
  system_name?: string;
  partners_site?: string;
  document_template_path?: string;
  filter_price?: string;
  tour_operator_contact?: string | null;
  sale?: string | number;
  sale_percent?: string | number;
  short_description?: string;
  description?: string;
  additional_info?: string;
  services?: string;
  not_in_price?: string;
  tour_days?: ProgramTourDTO[];
  services_array?: TourServicesDTO[];
  sales_array?: TourDiscountsDTO[];
  price?: string | number | null;
  monday?: string;
  booking_without_prepay: boolean;
  monday_title?: string;
  tuesday?: string;
  tuesday_title?: string;
  wednesday?: string;
  wednesday_title?: string;
  thursday?: string;
  thursday_title?: string;
  friday?: string;
  friday_title?: string;
  saturday?: string;
  saturday_title?: string;
  sunday?: string;
  sunday_title?: string;
  eightday?: string;
  eightday_title?: string;
  tour_group_placements?: {
    id?: number | string;
    hotel_string: string;
    description: string;
    price1: string | null;
    price2: string | null;
    price3: string | null;
    price4: string | null;
    price5: string | null;
  }[];
  tour_group_title?: string;
  settings: {
    paramsOrders?: IParamsOrder | null;
    tourGroup?: TableDescColumn[];
    photoOrders?: number[];
  };
  priority?: number;
  //данные из вкладки "Параметры"
  managers?: (string | undefined)[]; // менеджеры тура
  guides?: string[]; // гиды
  countries?: string[]; // страны
  cities?: string[]; // города
  transports?: string[]; // транспорт
  labels?: string[]; //вид тура
  tour_periods?: string[]; //время путешествия
  tour_directions?: string[]; //направление тура
  tour_tags?: string[]; // теги тура
  main_tag: string | null | undefined;
  //данные из вкладки "Даты тура"
  is_visible: boolean;
  tour_dates?: TourDatesDTO[];
  tour_hotels?: TourHotelsDTO[];
  photos?: string[];
  photo_album?: string | null;
  // note: string;
  // sale: string

  // status?: string; //active/archive
  // hotels?: (string | undefined)[];
  // hotel_rooms?: (string | undefined)[];
}

// редактирование тура - данные для отправки на сервер
export interface EditTourData {
  id: number;
  data: AddTourData;
}

// удаление тура - данные для отправки на сервер
export interface DeletePlacementData {
  id: number | string;
}

// удаление поездки - данные для отправки на сервер
export interface DeleteTripData {
  id: number | string;
}

export interface CityData {
  countries: (string | undefined)[];
}

// получение списка поездок (ранее назывались турами)
export const toursAPI = async ({
  page,
  manager,
  status,
  search,
  dateFrom,
  tour_directions,
  dateTo,
  sortById,
  sortByName,
  itemsPerPage,
  tour_type,
}: ToursData) => {
  const response = await axiosInstance.get<ToursResponse>('/api/v1/tour-dates', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      'tour.managers': manager,
      tour_directions: tour_directions,
      status: status,
      search: search,
      'start[before]': dateTo ? getTimezonelessDate(dateTo) : dateTo,
      'start[after]': dateFrom ? getTimezonelessDate(dateFrom) : dateFrom,
      'sort[id]': sortById,
      'sort[tour.name]': sortByName,
      tour_type: tour_type,
    },
  });
  return response;
};

// получение списка туров (реальных)
export const realToursAPI = async ({
  page,
  manager,
  status,
  search,
  dateFrom,
  dateTo,
  sortById,
  sortByName,
  is_visible,
  itemsPerPage,
}: ToursData) => {
  const response = await axiosInstance.get<TourRealShortResponse>('/api/v1/tours', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      'tour.managers': manager,
      'tour.status': status,
      common_search: search,
      'start[before]': dateTo,
      'start[after]': dateFrom,
      'sort[id]': sortById,
      'sort[tour.name]': sortByName,
      tour_type: 'tour',
      is_visible: is_visible,
    },
  });
  return response;
};

// получение списка всех туров
export const allToursAPI = async ({
  page,
  manager,
  search,
  sortById,
  sortByName,
  itemsPerPage,
  properties,
  tour_directions,
  tour_type,
  is_visible,
}: AllToursData) => {
  const response = await axiosInstance.get<AllToursResponse>('/api/v1/tours', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      managers: manager,
      common_search: search,
      tour_directions: tour_directions,
      'sort[id]': sortById,
      'sort[name]': sortByName,
      properties: properties,
      tour_type: 'tour',
      is_visible: is_visible,
    },
  });
  return response;
};

//получение тура по ID
export const getTourAPI = async ({ id, signal }: GetTourData) => {
  const response = await axiosInstance.get<TourResponse>(`/api/v1/tours/${id}`, {
    signal: signal,
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
export const getTourParamsAPI = async ({ id, signal }: GetTourData) => {
  const response = await axiosInstance.get<TourParams>(`/api/v1/tours/${id}/params`, {
    signal: signal,
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
//получение сокращенных дат тура
export const getTourDatesAPI = async ({ id, signal }: GetTourData) => {
  const response = await axiosInstance.get(`/api/v1/tour-dates/minimal`, {
    signal: signal,
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour: id,
      'sort[start]': 'asc',
      itemsPerPage: 300,
    },
  });
  return response;
};

//получение сокращенных дат тура
export const getTourHotelsMinAPI = async ({ id, signal }: GetTourData) => {
  const response = await axiosInstance.get<TourHotelResponse[]>(`/api/v1/tour-hotels/minimal`, {
    signal: signal,
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour: id,
      itemsPerPage: 300,
    },
  });
  return response;
};

//получение гостиницы по ID
export const getTourHotelAPI = async ({ id, signal }: GetTourData) => {
  const response = await axiosInstance.get(`/api/v1/tour-hotels/${id}`, {
    signal: signal,
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

//получение  даты тура по ID
export const getTourDateAPI = async ({ id, signal }: GetTourData) => {
  const response = await axiosInstance.get(`/api/v1/tour-dates/${id}`, {
    signal: signal,
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const getTourDatesPlacementAPI = async ({ tour_date }: { tour_date: string }) => {
  const response = await axiosInstance.get('/api/v1/tour-date-placements', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour_date: tour_date,
      itemsPerPage: 300,
    },
  });
  return response;
};

export const getTourNightsAPI = async ({ tour_date }: { tour_date: string }) => {
  const response = await axiosInstance.get('/api/v1/tour-additional-nights', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour_date: tour_date,
      itemsPerPage: 100,
    },
  });
  return response;
};

// добавление тура
export const addTourAPI = async (data?: AddTourData) => {
  const response = await axiosInstance.post<TourResponse>('/api/v1/tours', data);
  return response;
};

export const getTourProgramAPI = async ({ tourId }: { tourId: number }) => {
  const response = await axiosInstance.post<{
    result: TourDocumentResponse[];
  }>(
    '/api/v1/tour-documents',
    {
      document_type: 'tourProgram',
      tour: `/api/v1/tours/${tourId}`,
    },
    {
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json;charset=UTF-8',
      },
    }
  );
  return response;
};

// получения списка туроператоров
export const getTourOperatorsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/tour-operators', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка стран
export const getCountriesAPI = async () => {
  const response = await axiosInstance.get('/api/v1/countries', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка гидов
export const getGuidesAPI = async () => {
  const response = await axiosInstance.get('/api/v1/guides', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка городов
export const getCitiesAPI = async ({ countries }: CityData) => {
  const response = await axiosInstance.get('/api/v1/cities', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      country: countries,
    },
  });
  return response;
};

// получения списка транспорта
export const getTransportsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/transports', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка видов туров
export const getTourTypesAPI = async () => {
  const response = await axiosInstance.get('/api/v1/tour-labels', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка времени путешествия
export const getTourPeriodsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/tour-periods', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка направлений тура
export const getDirectionsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/tour-directions', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// список типов номеров
export const getHotelTypesAPI = async () => {
  const response = await axiosInstance.get('/api/v1/hotel-room-types', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка тегов тура
export const getTagsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/tour-tags', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// редактирование тура по ID
export const editTourAPI = async ({ id, data }: EditTourData) => {
  const response = await axiosInstance.put<TourResponse>(`/api/v1/tours/${id}`, data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return response;
};

// удаление гостиницы по ID
export const deletePlacementAPI = async ({ id }: DeletePlacementData) => {
  const response = await axiosInstance.delete(`/api/v1/tour-date-placements/${id}`);
  return response;
};
// удаление гостиницы по ID
export const deleteHotelPlacementAPI = async ({ id }: DeletePlacementData) => {
  const response = await axiosInstance.delete(`/api/v1/tour-hotel-placements/${id}`);
  return response;
};
export const deleteNightAPI = async ({ id }: DeletePlacementData) => {
  const response = await axiosInstance.delete(`/api/v1/tour-additional-nights/${id}`);
  return response;
};

// удаление поездки по ID
export const deleteTripAPI = async ({ id }: DeleteTripData) => {
  const response = await axiosInstance.delete(`/api/v1/tour-dates/${id}`);
  return response;
};

// удаление гостиницы по ID
export const deleteHotelAPI = async ({ id }: DeleteTripData) => {
  const response = await axiosInstance.delete(`/api/v1/tour-hotels/${id}`);
  return response;
};
// получения списка гостиниц вертушек
export const getTourHotelsAPI = async ({
  page,
  dateFrom,
  dateTo,
  itemsPerPage,
  tour,
}: {
  page: number;
  dateFrom?: string;
  dateTo?: string;
  itemsPerPage: number;
  tour: string;
}) => {
  const response = await axiosInstance.get('/api/v1/tour-hotels', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour: tour,
      page: page,
      itemsPerPage: itemsPerPage,
      'tour_hotel_placements.ending[after]': dateTo,
      'tour_hotel_placements.start[before]': dateFrom,
    },
  });
  return response;
};

export const getTourHotelPlacementAPI = async ({
  page,
  dateFrom,
  dateTo,
  itemsPerPage,
  tour,
}: {
  page?: number;
  dateFrom?: string;
  dateTo?: string;
  itemsPerPage?: number;
  tour: string;
}) => {
  const response = await axiosInstance.get('/api/v1/tour-hotel-placements', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour_hotel: tour,
      page: page,
      itemsPerPage: 300,
      'start[after]': dateTo,
      'ending[before]': dateFrom,
    },
  });
  return response;
};

export const deleteDatesAPI = async ({ id }: { id: number[] }) => {
  const response = await axiosInstance.post(`/api/v1/tour-dates/delete`, {
    ids: id,
  });
  return response;
};
export const deleteHotelsAPI = async ({ id }: { id: number[] }) => {
  const response = await axiosInstance.post(`/api/v1/tour-hotels/delete`, {
    ids: id,
  });
  return response;
};

export const downloadAllToursAPI = async () => {
  const response = await axiosInstance.get(`/api/v1/tours/download`);
  return response;
};
export const downloadLastMonthAPI = async () => {
  const response = await axiosInstance.get(`/api/v1/tours/download/last`);
  return response;
};
