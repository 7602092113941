import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import clsx from 'clsx';
import { Switch } from '@mantine/core';
import { TourForm } from '../components/TourForm';
import { PrevPageIcon } from 'components/icons';
import { RouteNames } from 'core/constants/routeNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setErrorTourOperator, setErrorTourTitle, setIdTour, setIsEditedTour } from 'store/entities/tours/toursSlice';
import { AddTourData } from 'services/tours/toursApi.service';
import {
  tourProgramFactoryOut,
  tourServicesFactoryOut,
  tourDiscountsFactoryOut,
  tourDatesFactoryOut,
  tourParamFactoryOut,
} from 'services/tours/tours.factory';
import { setPreSaveIsVisible, setPreSaveTourName, setPreSaveTourTemplate } from 'store/entities/tours/preSaveTourSlice';
import { addTour } from 'store/action-creators/tours';
import { ModalWarning } from '../components/TourForm/ModalWarning';

import styles from './TourCreate.module.scss';
import { listOfTourTemplates } from 'core/constants/lists';
import { Select } from 'ui/Select';
import { getTemplate } from 'core/utils/getTemplate';
import { getCurrencyToServer } from 'core/utils/getCurrency';

export const TourCreate: FC = () => {
  const dispatch = useAppDispatch();
  const { isEditedTour, isSuccess, tags, isError, errorsValidateTour, idNewTour } = useAppSelector(
    (state) => state.tours
  );
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [tourData, setTourData] = useState<AddTourData | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const templates: string[] = listOfTourTemplates;
  const [template, setTemplate] = useState(templates[0]);
  const [isVisible, setisVisible] = useState(false);
  // собираем данные для отправки запроса на сервер
  useEffect(() => {
    setTourData({
      ...tourData,

      //сбор данных для запроса из вкладки "описание тура"
      name: preSaveTour2.name,
      duration: Number(preSaveTour2.descriptionInfo?.duration),
      template: getTemplate(template),
      commission: preSaveTour2.descriptionInfo.commission,
      currency: getCurrencyToServer(preSaveTour2.descriptionInfo.currency!),
      meta_title: preSaveTour2.descriptionInfo.meta_tags.title,
      meta_description: preSaveTour2.descriptionInfo.meta_tags.description,
      tour_operator_contact:
        preSaveTour2.descriptionInfo?.tourOperator?.name != 'Акварель'
          ? `/api/v1/contacts/${preSaveTour2.descriptionInfo?.tourOperator?.id}`
          : null,
      sale: preSaveTour2.descriptionInfo?.discount?.toString(),
      short_description: preSaveTour2.descriptionInfo?.shortDescription,
      description: preSaveTour2.descriptionInfo?.fullDescription,
      additional_info: preSaveTour2.descriptionInfo.addInfo,
      services: preSaveTour2.descriptionInfo?.priceIncludes,
      not_in_price: preSaveTour2.descriptionInfo?.priceNoIncludes,
      system_name: preSaveTour2.descriptionInfo.system_name,
      partners_site: preSaveTour2.descriptionInfo.partners_site,
      document_template_path: preSaveTour2.descriptionInfo.document_template_path,
      filter_price: preSaveTour2.descriptionInfo.filter_price,
      tour_days: preSaveTour2.descriptionInfo?.tourProgram?.map((day) => tourProgramFactoryOut(day)),
      services_array: preSaveTour2.descriptionInfo?.additionalServices?.map((service) =>
        tourServicesFactoryOut(service)
      ),
      sales_array: preSaveTour2.descriptionInfo?.additionalDiscounts?.map((discount) =>
        tourDiscountsFactoryOut(discount)
      ),
      is_visible: preSaveTour2.is_visible,
      price: preSaveTour2.descriptionInfo?.price?.toString(),
      booking_without_prepay: preSaveTour2.descriptionInfo.booking_without_prepay,
      monday: preSaveTour2.descriptionInfo.monday,
      tuesday: preSaveTour2.descriptionInfo.tuesday,
      wednesday: preSaveTour2.descriptionInfo.wednesday,
      thursday: preSaveTour2.descriptionInfo.thursday,
      friday: preSaveTour2.descriptionInfo.friday,
      saturday: preSaveTour2.descriptionInfo.saturday,
      sunday: preSaveTour2.descriptionInfo.sunday,
      eightday: preSaveTour2.descriptionInfo.eightday,
      eightday_title: preSaveTour2.descriptionInfo.eightday_title,
      monday_title: preSaveTour2.descriptionInfo.monday_title,
      tuesday_title: preSaveTour2.descriptionInfo.tuesday_title,
      wednesday_title: preSaveTour2.descriptionInfo.wednesday_title,
      thursday_title: preSaveTour2.descriptionInfo.thursday_title,
      friday_title: preSaveTour2.descriptionInfo.friday_title,
      saturday_title: preSaveTour2.descriptionInfo.saturday_title,
      sunday_title: preSaveTour2.descriptionInfo.sunday_title,
      //сбор данных для запроса из вкладки "параметры"
      managers: preSaveTour2.tourParameters?.tourManager?.map((manager) => manager['@id']),
      guides: preSaveTour2.tourParameters?.guide?.map((direction) => {
        return direction['@id']!;
      }),
      countries: preSaveTour2.tourParameters?.country?.map((direction) => {
        return direction['@id']!;
      }),
      cities: preSaveTour2.tourParameters?.city?.map((direction) => {
        return direction['@id']!;
      }),
      transports: preSaveTour2.tourParameters?.transport?.map((direction) => {
        return direction['@id']!;
      }),
      labels: preSaveTour2.tourParameters?.tourType?.map((direction) => {
        return direction['@id']!;
      }),
      tour_periods: preSaveTour2.tourParameters?.travelTime?.map((direction) => {
        return direction['@id']!;
      }),
      tour_directions: preSaveTour2.tourParameters?.direction?.map((direction) => {
        return direction['@id']!;
      }),
      tour_tags: preSaveTour2.tourParameters?.tags?.map((direction) => {
        return direction['@id']!;
      }),
      main_tag:
        tags.length > 0
          ? preSaveTour2.tourParameters.main_tag
            ? preSaveTour2.tourParameters?.tags?.find((el) => el.name === preSaveTour2.tourParameters.main_tag)?.['@id']
            : null
          : undefined,
      //сбор данных для запроса из вкладки "даты тура"
      tour_dates: preSaveTour2.tripsInfo?.map((trip) =>
        tourDatesFactoryOut(trip, preSaveTour2.descriptionInfo.duration)
      ),
      tour_group_title: preSaveTour2.descriptionInfo.tour_group_title,
      tour_group_placements: preSaveTour2.descriptionInfo?.tour_group_placements?.map((el) => {
        if (el.id) {
          return {
            id: `/api/v1/tour-group-placements/${el.id}`,
            hotel_string: el.hotel_string,
            description: el.description,
            price1: el.price1 === '' ? null : el.price1,
            price2: el.price2 === '' ? null : el.price2,
            price3: el.price3 === '' ? null : el.price3,
            price4: el.price4 === '' ? null : el.price4,
            price5: el.price5 === '' ? null : el.price5,
          };
        } else {
          return {
            hotel_string: el.hotel_string,
            description: el.description,
            price1: el.price1 === '' ? null : el.price1,
            price2: el.price2 === '' ? null : el.price2,
            price3: el.price3 === '' ? null : el.price3,
            price4: el.price4 === '' ? null : el.price4,
            price5: el.price5 === '' ? null : el.price5,
          };
        }
      }),
      settings: {
        tourGroup: preSaveTour2.descriptionInfo.settings?.tourGroup,
      },
      priority: preSaveTour2.descriptionInfo.priority ? preSaveTour2.descriptionInfo.priority : 1,
    });
  }, [preSaveTour2, template]);

  //нажатие на кнопку сохранить в форме - отправляем данные на сервер
  const handleCreate = () => {
    dispatch(setIsEditedTour(true));
    if (title === '') {
      dispatch(setErrorTourTitle(true));
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Заполните поле с названием тура!' });
    }
    if (preSaveTour2.descriptionInfo.tourOperator === null) {
      dispatch(setErrorTourOperator(true));
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Заполните поле с туроператором!' });
    }
    if (tourData && title !== '' && preSaveTour2.descriptionInfo.tourOperator !== null) {
      // console.log(tourData.template);
      dispatch(addTour(tourData));
    }
  };

  // если поле с названием заполнено, снимаем ошибку
  useEffect(() => {
    if (title !== '') {
      dispatch(setErrorTourTitle(false));
    }
  }, [title]);

  // если поле с оператором заполнено, снимаем ошибку
  useEffect(() => {
    if (preSaveTour2.descriptionInfo.tourOperator !== null) {
      dispatch(setErrorTourOperator(false));
    }
  }, [preSaveTour2.descriptionInfo.tourOperator]);

  // при вводе данных в поля, после потери фокуса isEditedTour становится true. в этот момент идет отправка данных в presave
  // от presave меняется tourdata
  // после этого надо поменять значение в false, чтобы отслеживать дальнейшие изменения
  useEffect(() => {
    if (isEditedTour) {
      dispatch(setIsEditedTour(false));
    }
  }, [tourData]);

  // при изменении названия сохраняем данные в presave
  useEffect(() => {
    if (isEditedTour) {
      dispatch(setPreSaveTourName(title));
      dispatch(setPreSaveTourTemplate(getTemplate(template)));
    }
  }, [isEditedTour]);

  useEffect(() => {
    if (isSuccess === 'ADD_TOUR') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Тур успешно создан' });
    }
    if (isError === 'ADD_TOUR') {
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Ошибка отправки данных на сервер' });
    }
  }, [isSuccess, isError]);

  // при создании тура, сохраняем id из ответа в стейт.
  // если он есть (0 - нет), то после создания сразу перенаправляем на страницу тура.
  // это дает возможность корректно добавлять поездки к туру
  useEffect(() => {
    if (idNewTour !== 0) {
      navigate(`${RouteNames.TOURS}/${idNewTour}`);
      dispatch(setIdTour(0));
    }
  }, [idNewTour]);

  // модалка по подтверждению действия
  const handleBackButton = () => {
    open();
  };

  const handleClose = () => {
    navigate(`${RouteNames.TOURS}`);
  };

  const handleCancel = () => {
    close();
  };

  return (
    <section className={styles.create}>
      <div className={styles.top}>
        <button className={styles.buttonBack} onClick={handleBackButton}>
          <PrevPageIcon width={33} height={33} fill="#5B6572" />
        </button>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Новый тур"
          className={clsx(styles.tourName, errorsValidateTour.title && styles.tourNameError)}
          onBlur={() => {
            dispatch(setIsEditedTour(true));
          }}
        />
        <div className={styles.options}>
          <div className={styles.templates}>
            <span>Шаблон</span>
            <Select
              selected={template}
              data={templates}
              setSelect={(a) => {
                setTemplate(a);
                dispatch(setPreSaveTourTemplate(getTemplate(a)));
              }}
              className={styles.select}
              onBlur={() => {
                dispatch(setIsEditedTour(true));
              }}
            />
          </div>
          <Switch
            styles={{
              body: {
                alignItems: 'center',
              },
              labelWrapper: {
                paddingBottom: 2.5,
              },
              label: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '22px',
                color: '#263B53',
              },
            }}
            labelPosition="left"
            checked={isVisible}
            onChange={(event) => {
              setisVisible(event.currentTarget.checked);
              setPreSaveIsVisible(event.currentTarget.checked);
            }}
            color="green"
            label="Отображать тур на сайте"
          />
        </div>
      </div>
      <ModalWarning opened={opened} close={close} handleClose={handleClose} handleCancel={handleCancel} />
      <div className={styles.content}>
        <TourForm buttonSend={handleCreate} template={template} />
      </div>
    </section>
  );
};
