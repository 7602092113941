import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { Input } from 'ui/Input';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DescriptionInfo } from 'pages/Tours/Tours.types';
import { DescriptionInfoTabProps } from './DescriptionInfoTab.types';
import { setIsEditedTour } from 'store/entities/tours/toursSlice';
import { TextEditor } from 'ui/TextEditor';
import { TourProgram } from '../TourProgram';
import { TourProgramDay } from '../TourProgram/TourProgram.types';
import { ServiceProps, TableColumn } from 'components/AddService/AddService.type';
import { AddService } from 'components/AddService';
import { getTourOperators, getTourProgram } from 'store/action-creators/tours';
import { SelectItemType } from 'ui/SelectNew/SelectNew.type';
import upload from '../../../../../assets/icons/upload.svg';
import {
  setPreSaveBookingPrepay,
  setPreSaveDescColumns,
  setPreSaveDescriptionInfo,
  setPreSaveEightday,
  setPreSaveEightdayTitle,
  setPreSaveFriday,
  setPreSaveFridayTitle,
  setPreSaveMonday,
  setPreSaveMondayTitle,
  setPreSavePriority,
  setPreSaveSaturday,
  setPreSaveSaturdayTitle,
  setPreSaveSunday,
  setPreSaveSundayTitle,
  setPreSaveThursday,
  setPreSaveThursdayTitle,
  setPreSaveTourAddGroupDesc,
  setPreSaveTourAddInfo,
  setPreSaveTourAddTitleDesc,
  setPreSaveTourAdditionalDiscounts,
  setPreSaveTourAdditionalServices,
  setPreSaveTourComission,
  setPreSaveTourCurrency,
  setPreSaveTourDiscount,
  setPreSaveTourDiscountPersent,
  setPreSaveTourDocument,
  setPreSaveTourDuration,
  setPreSaveTourFilterPrice,
  setPreSaveTourFullDescription,
  setPreSaveTourOperator,
  setPreSaveTourPartnersSite,
  setPreSaveTourPrice,
  setPreSaveTourPriceIncludes,
  setPreSaveTourPriceNoIncludes,
  setPreSaveTourProgram,
  setPreSaveTourShortDescription,
  setPreSaveTourShortSystemName,
  setPreSaveTuesday,
  setPreSaveTuesdayTitle,
  setPreSaveWednesday,
  setPreSaveWednesdayTitle,
} from 'store/entities/tours/preSaveTourSlice';

import styles from './DescriptionInfoTab.module.scss';
import { Button } from '../../../../../ui/Button';
import { DownloadIcon } from '../../../../../components/icons/DownloadIcon';
import { notifications } from '@mantine/notifications';
import { addDocument, getPartners, getTourOperatorsForSearch } from 'store/action-creators/partners';
import { HelperIcon } from 'components/icons/HelperIcon';
import { ToursTemplates } from 'core/constants/enums';
import { Select } from 'ui/Select';
import { customModule } from './helper';
import { AddDescription } from 'components/AddDescription';
import { DescProps, TableDescColumn } from 'components/AddDescription/AddService.type';
import { SelectSearch } from 'ui/SelectSearch';
import { RemoveIcon } from 'components/icons';
import { Checkbox } from 'ui/Checkbox';
import { AddSales } from 'components/AddSales';
import { SalesProps } from 'components/AddSales/AddSales.type';
import ImageFile from 'components/TextEditorOrder/ImageFile';
import { srcToFile } from 'services/orders/orders.adapter';

const columnsAddService: TableColumn[] = [
  {
    id: 'icons',
    name: 'Иконка',
  },
  {
    id: 'service',
    name: 'Услуга',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'view',
    name: 'Отображение на сайте',
  },
];
const columnsAddDiscount: TableColumn[] = [
  {
    id: 'service',
    name: 'Название',
  },
  {
    id: 'sex',
    name: 'Условие возраста',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  // {
  //   id: 'price',
  //   name: 'Цена (%)',
  // },
];

export const DescriptionInfoTab: FC<DescriptionInfoTabProps> = ({ descriptionInfo, template }) => {
  const { isResetPreSaveTour, isEditedTour, isSuccess, isError, errorsValidateTour, currentTour } = useAppSelector(
    (state) => state.tours
  );
  const { tourOperatorsForSearch } = useAppSelector((state) => state.partners);
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const dispatch = useAppDispatch();
  const [tourOperator, setTourOperator] = useState<SelectItemType | null>();
  const [shortDescription, setShortDescription] = useState<string>();
  const [fullDescription, setFullDescription] = useState<string>();
  const [addInfo, setaddInfo] = useState<string>();
  const [tourProgram, setTourProgram] = useState<TourProgramDay[] | null>(null);
  const [priceIncludes, setPriceIncludes] = useState<string>();
  const [priceNoIncludes, setPriceNoIncludes] = useState<string>();
  const [addServices, setAddServices] = useState<ServiceProps[]>([]);
  const [addDesc, setAddDesc] = useState<DescProps[]>([]);
  const [searchOperatorVal, setSearchOperatorVal] = useState('');
  const [bookingWithoutPrepay, setBookingWithoutPrepay] = useState(false);
  const [meta_tags, setMeta_tags] = useState<{ title: string; description: string }>({
    title: '',
    description: '',
  });
  const tourOperatorList = useMemo(
    () =>
      searchOperatorVal != ''
        ? tourOperatorsForSearch

            .filter((el) => el.name?.toLowerCase().includes(searchOperatorVal.toLowerCase()))
            .map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            })
            .concat({ id: 0, name: 'Акварель' })
        : searchOperatorVal.includes('Акварель')
        ? [{ id: 0, name: 'Акварель' }]
        : [],
    [tourOperatorsForSearch, searchOperatorVal]
  );

  const handleTourOperatorKey = () => {
    if (searchOperatorVal != '') {
      dispatch(
        getTourOperatorsForSearch({
          search: searchOperatorVal,
          properties: ['id', 'full_name'],
          type: '/api/v1/contact-types/2',
          itemsPerPage: 100,
        })
      );
      return;
    }
  };
  const [addDescColumns, setAddDescColumns] = useState<TableDescColumn[]>(
    Array.from({ length: 7 }, () => {
      return { name: '' };
    })
  );
  const [titleDesc, setTitleDesc] = useState('');
  const [addDiscounts, setAddDiscounts] = useState<SalesProps[]>([]);
  const [viewHelp, setViewHelp] = useState(false);
  const [viewDurationHelp, setViewDurationHelp] = useState(false);
  const [monday, setMonday] = useState('');
  const [tuesday, setTuesday] = useState('');
  const [wednesday, setWednesday] = useState('');
  const [thursday, setThursday] = useState('');
  const [friday, setFriday] = useState('');
  const [saturday, setSaturday] = useState('');
  const [sunday, setSunday] = useState('');
  const [eightday, setEightday] = useState('');

  //начальные значения для стандартных полей формы
  const initialValues: DescriptionInfo = {
    meta_tags: {
      title: '',
      description: '',
    },
    priority: preSaveTour2.descriptionInfo.priority || descriptionInfo?.priority || 1,
    duration: template === ToursTemplates.ONEDAY ? 0 : preSaveTour2.descriptionInfo?.duration,
    discount: preSaveTour2.descriptionInfo?.discount || descriptionInfo?.discount || 0,
    discount_percent: preSaveTour2.descriptionInfo?.discount_percent || descriptionInfo?.discount_percent || 0,
    shortDescription: shortDescription || '',
    fullDescription: fullDescription || '',
    priceIncludes: priceIncludes || '',
    priceNoIncludes: priceNoIncludes || '',
    price: preSaveTour2.descriptionInfo?.price || descriptionInfo?.price || 0,
    addInfo: addInfo || '',
    system_name: preSaveTour2.descriptionInfo.system_name || descriptionInfo?.system_name || '',
    partners_site: preSaveTour2.descriptionInfo.partners_site || descriptionInfo?.partners_site || '',
    filter_price: preSaveTour2.descriptionInfo.filter_price || descriptionInfo?.filter_price || '',
    commission: preSaveTour2.descriptionInfo.commission || descriptionInfo?.commission || '',
    currency: preSaveTour2.descriptionInfo.currency || descriptionInfo?.currency || '',
    monday: monday || '',
    monday_title: preSaveTour2.descriptionInfo.monday_title || descriptionInfo?.monday_title || '',
    tuesday: tuesday || '',
    tuesday_title: preSaveTour2.descriptionInfo.tuesday_title || descriptionInfo?.tuesday_title || '',
    wednesday: wednesday || '',
    wednesday_title: preSaveTour2.descriptionInfo.wednesday_title || descriptionInfo?.wednesday_title || '',
    thursday: thursday || '',
    thursday_title: preSaveTour2.descriptionInfo.thursday_title || descriptionInfo?.thursday_title || '',
    friday: friday || '',
    friday_title: preSaveTour2.descriptionInfo.friday_title || descriptionInfo?.friday_title || '',
    saturday: saturday || '',
    saturday_title: preSaveTour2.descriptionInfo.saturday_title || descriptionInfo?.saturday_title || '',
    sunday: sunday || '',
    sunday_title: preSaveTour2.descriptionInfo.sunday_title || descriptionInfo?.sunday_title || '',
    eightday: eightday || '',
    eightday_title: preSaveTour2.descriptionInfo.eightday_title || descriptionInfo?.eightday_title || '',
    booking_without_prepay: bookingWithoutPrepay || false,
    document_template_path: preSaveTour2.descriptionInfo.document_template_path || '',
    file: preSaveTour2.descriptionInfo.file || undefined,
  };
  const formDescriptionInfo = useForm({
    initialValues,
  });
  console.log(preSaveTour2.descriptionInfo.duration);
  useEffect(() => {
    const load = async () => {
      const res = await Promise.all(
        [preSaveTour2.descriptionInfo.document_template_path].map(async (i: string) => {
          return await srcToFile(`${process.env.REACT_APP_BASE_API_URL}${i}`, i.substring(i.lastIndexOf('/') + 1));
        })
      );
      formDescriptionInfo.setFieldValue('file', res);
    };
    if (preSaveTour2.descriptionInfo.document_template_path) {
      load();
    }
  }, [preSaveTour2.descriptionInfo.document_template_path]);

  //получение списка туроператоров с сервера
  useEffect(() => {
    dispatch(getTourOperators());
    dispatch(
      getPartners({
        page: 1,
        itemsPerPage: 100,
        type: '/api/v1/contact-types/2',
      })
    );
  }, []);

  //начальные значения для управляемых полей
  useEffect(() => {
    setTourOperator(preSaveTour2.descriptionInfo?.tourOperator || descriptionInfo?.tourOperator || null);
    setShortDescription(preSaveTour2.descriptionInfo?.shortDescription || descriptionInfo?.shortDescription || '');
    setFullDescription(preSaveTour2.descriptionInfo?.fullDescription || descriptionInfo?.fullDescription || '');
    setTourProgram(preSaveTour2.descriptionInfo?.tourProgram || descriptionInfo?.tourProgram || []);
    setPriceIncludes(preSaveTour2.descriptionInfo?.priceIncludes || descriptionInfo?.priceIncludes || '');
    setPriceNoIncludes(preSaveTour2.descriptionInfo?.priceNoIncludes || descriptionInfo?.priceNoIncludes || '');
    setAddServices(preSaveTour2.descriptionInfo?.additionalServices || descriptionInfo?.additionalServices || []);
    setAddDesc(preSaveTour2.descriptionInfo.tour_group_placements || descriptionInfo?.tour_group_placements || []);
    setTitleDesc(preSaveTour2.descriptionInfo.tour_group_title || descriptionInfo?.tour_group_title || '');
    setAddDescColumns(
      preSaveTour2.descriptionInfo.settings &&
        preSaveTour2.descriptionInfo.settings?.tourGroup &&
        preSaveTour2.descriptionInfo.settings?.tourGroup?.length > 0
        ? preSaveTour2.descriptionInfo.settings?.tourGroup
        : Array.from({ length: 7 }, () => {
            return { name: '' };
          })
    );
    setAddDiscounts(preSaveTour2.descriptionInfo?.additionalDiscounts || descriptionInfo?.additionalDiscounts || []);
    setaddInfo(preSaveTour2.descriptionInfo.addInfo || descriptionInfo?.addInfo || '');
    setMonday(preSaveTour2.descriptionInfo.monday || descriptionInfo?.monday || '');
    setTuesday(preSaveTour2.descriptionInfo.tuesday || descriptionInfo?.tuesday || '');
    setWednesday(preSaveTour2.descriptionInfo.wednesday || descriptionInfo?.wednesday || '');
    setThursday(preSaveTour2.descriptionInfo.thursday || descriptionInfo?.thursday || '');
    setFriday(preSaveTour2.descriptionInfo.friday || descriptionInfo?.friday || '');
    setSaturday(preSaveTour2.descriptionInfo.saturday || descriptionInfo?.saturday || '');
    setSunday(preSaveTour2.descriptionInfo.sunday || descriptionInfo?.sunday || '');
    setEightday(preSaveTour2.descriptionInfo.eightday || descriptionInfo?.eightday || '');
    setBookingWithoutPrepay(
      preSaveTour2.descriptionInfo.booking_without_prepay || descriptionInfo?.booking_without_prepay || false
    );
    setMeta_tags(descriptionInfo?.meta_tags || meta_tags);
  }, []);

  useEffect(() => {
    formDescriptionInfo.setFieldValue('shortDescription', shortDescription);
  }, [shortDescription]);

  useEffect(() => {
    formDescriptionInfo.setFieldValue('fullDescription', fullDescription);
  }, [fullDescription]);

  useEffect(() => {
    formDescriptionInfo.setFieldValue('priceIncludes', priceIncludes);
  }, [priceIncludes]);

  useEffect(() => {
    formDescriptionInfo.setFieldValue('priceNoIncludes', priceNoIncludes);
  }, [priceNoIncludes]);

  useEffect(() => {
    formDescriptionInfo.setFieldValue('addInfo', addInfo);
  }, [addInfo]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('monday', monday);
  }, [monday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('tuesday', tuesday);
  }, [tuesday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('wednesday', wednesday);
  }, [wednesday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('thursday', thursday);
  }, [thursday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('friday', friday);
  }, [friday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('saturday', saturday);
  }, [saturday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('sunday', sunday);
  }, [sunday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('eightday', eightday);
  }, [eightday]);
  useEffect(() => {
    formDescriptionInfo.setFieldValue('booking_without_prepay', bookingWithoutPrepay);
  }, [bookingWithoutPrepay]);
  //обновление списка дополнительных услуг, когда меняются входящие данные
  useEffect(() => {
    if (descriptionInfo?.additionalServices) {
      setAddServices(descriptionInfo?.additionalServices);
    }
  }, [descriptionInfo?.additionalServices]);

  //обновление списка дополнительных скидок, когда меняются входящие данные
  useEffect(() => {
    if (descriptionInfo?.additionalDiscounts) {
      setAddDiscounts(descriptionInfo?.additionalDiscounts);
    }
  }, [descriptionInfo?.additionalDiscounts]);

  useEffect(() => {
    if (descriptionInfo?.settings && descriptionInfo?.settings.tourGroup) {
      setAddDescColumns(descriptionInfo?.settings.tourGroup);
    }
  }, [descriptionInfo?.settings]);

  useEffect(() => {
    if (descriptionInfo?.tour_group_placements) {
      setAddDesc(descriptionInfo?.tour_group_placements);
    }
  }, [descriptionInfo?.settings]);
  // сбор данных из полей формы
  useEffect(() => {
    if (formDescriptionInfo.values.duration !== null && formDescriptionInfo.values.duration !== undefined) {
      dispatch(setPreSaveTourDuration(formDescriptionInfo.values.duration));
    }
    if (formDescriptionInfo.values.discount !== null && formDescriptionInfo.values.discount !== undefined) {
      dispatch(setPreSaveTourDiscount(formDescriptionInfo.values.discount));
    }
    if (
      formDescriptionInfo.values.discount_percent !== null &&
      formDescriptionInfo.values.discount_percent !== undefined
    ) {
      dispatch(setPreSaveTourDiscountPersent(formDescriptionInfo.values.discount_percent));
    }
    if (formDescriptionInfo.values.shortDescription) {
      dispatch(setPreSaveTourShortDescription(formDescriptionInfo.values.shortDescription));
    }
    if (formDescriptionInfo.values.system_name) {
      dispatch(setPreSaveTourShortSystemName(formDescriptionInfo.values.system_name));
    }
    dispatch(setPreSaveTourPartnersSite(String(formDescriptionInfo.values.partners_site)));

    if (formDescriptionInfo.values.fullDescription) {
      dispatch(setPreSaveTourFullDescription(formDescriptionInfo.values.fullDescription));
    }
    if (formDescriptionInfo.values.addInfo) {
      dispatch(setPreSaveTourAddInfo(formDescriptionInfo.values.addInfo));
    }
    if (formDescriptionInfo.values.addInfo) {
      dispatch(setPreSaveTourAddInfo(formDescriptionInfo.values.addInfo));
    }
    if (formDescriptionInfo.values.priceIncludes) {
      dispatch(setPreSaveTourPriceIncludes(formDescriptionInfo.values.priceIncludes));
    }
    if (formDescriptionInfo.values.priceNoIncludes) {
      dispatch(setPreSaveTourPriceNoIncludes(formDescriptionInfo.values.priceNoIncludes));
    }
    if (formDescriptionInfo.values.filter_price) {
      dispatch(setPreSaveTourFilterPrice(formDescriptionInfo.values.filter_price));
    }
    if (formDescriptionInfo.values.commission) {
      dispatch(setPreSaveTourComission(formDescriptionInfo.values.commission));
    }
    if (formDescriptionInfo.values.monday) {
      dispatch(setPreSaveMonday(formDescriptionInfo.values.monday));
    }
    if (formDescriptionInfo.values.monday_title || formDescriptionInfo.values.monday_title === '') {
      dispatch(setPreSaveMondayTitle(formDescriptionInfo.values.monday_title));
    }
    if (formDescriptionInfo.values.tuesday) {
      dispatch(setPreSaveTuesday(formDescriptionInfo.values.tuesday));
    }
    if (formDescriptionInfo.values.tuesday_title || formDescriptionInfo.values.tuesday_title === '') {
      dispatch(setPreSaveTuesdayTitle(formDescriptionInfo.values.tuesday_title));
    }
    if (formDescriptionInfo.values.wednesday) {
      dispatch(setPreSaveWednesday(formDescriptionInfo.values.wednesday));
    }
    if (formDescriptionInfo.values.wednesday_title || formDescriptionInfo.values.wednesday_title === '') {
      dispatch(setPreSaveWednesdayTitle(formDescriptionInfo.values.wednesday_title));
    }
    if (formDescriptionInfo.values.thursday) {
      dispatch(setPreSaveThursday(formDescriptionInfo.values.thursday));
    }
    if (formDescriptionInfo.values.thursday_title || formDescriptionInfo.values.thursday_title === '') {
      dispatch(setPreSaveThursdayTitle(formDescriptionInfo.values.thursday_title));
    }
    if (formDescriptionInfo.values.friday) {
      dispatch(setPreSaveFriday(formDescriptionInfo.values.friday));
    }
    if (formDescriptionInfo.values.friday_title || formDescriptionInfo.values.friday_title === '') {
      dispatch(setPreSaveFridayTitle(formDescriptionInfo.values.friday_title));
    }
    if (formDescriptionInfo.values.saturday) {
      dispatch(setPreSaveSaturday(formDescriptionInfo.values.saturday));
    }
    if (formDescriptionInfo.values.saturday_title || formDescriptionInfo.values.saturday_title === '') {
      dispatch(setPreSaveSaturdayTitle(formDescriptionInfo.values.saturday_title));
    }
    if (formDescriptionInfo.values.sunday) {
      dispatch(setPreSaveSunday(formDescriptionInfo.values.sunday));
    }
    if (formDescriptionInfo.values.sunday_title || formDescriptionInfo.values.sunday_title === '') {
      dispatch(setPreSaveSundayTitle(formDescriptionInfo.values.sunday_title));
    }
    if (formDescriptionInfo.values.eightday) {
      dispatch(setPreSaveEightday(formDescriptionInfo.values.eightday));
    }
    if (formDescriptionInfo.values.eightday_title || formDescriptionInfo.values.eightday_title === '') {
      dispatch(setPreSaveEightdayTitle(formDescriptionInfo.values.eightday_title));
    }

    dispatch(setPreSaveBookingPrepay(formDescriptionInfo.values.booking_without_prepay));

    if (formDescriptionInfo.values.priority) {
      dispatch(setPreSavePriority(formDescriptionInfo.values.priority));
    }
    if (formDescriptionInfo.values.price !== null && formDescriptionInfo.values.price !== undefined) {
      dispatch(setPreSaveTourPrice(formDescriptionInfo.values.price));
    }
  }, [formDescriptionInfo.values]);

  //при изменении туроператора сохраняем его в preSave
  const handleTourOperator = (operator: SelectItemType | null | undefined) => {
    if (operator) {
      dispatch(setPreSaveTourOperator(operator));
    }
  };

  const programmRef = useRef<TourProgramDay[]>();
  const handleTourProgram = (program: TourProgramDay[]) => {
    if (program.length || (programmRef.current && programmRef.current?.length > program.length)) {
      dispatch(setPreSaveTourProgram(program));
    }
    programmRef.current = program;
  };

  const handleAddService = (services: ServiceProps[]) => {
    if (services) {
      dispatch(setPreSaveTourAdditionalServices(services));
    }
  };

  const handleAddDiscounts = (discounts: SalesProps[]) => {
    if (discounts) {
      dispatch(setPreSaveTourAdditionalDiscounts(discounts));
    }
  };

  const handleAddDesc = (rows: DescProps[]) => {
    if (rows) {
      dispatch(setPreSaveTourAddGroupDesc(rows));
    }
  };

  const handleAddTitleDesc = (value: string) => {
    if (value) {
      dispatch(setPreSaveTourAddTitleDesc(value));
    }
  };

  const handleAddColumns = (value: TableDescColumn[]) => {
    if (value) {
      dispatch(setPreSaveDescColumns({ tourGroup: value }));
    }
  };
  //сброс всех предварительно сохраненных данных в полях формы. работает когда уходим по страницы тура.
  useEffect(() => {
    if (isResetPreSaveTour) {
      formDescriptionInfo.reset();
      formDescriptionInfo.setFieldValue('duration', initialValues.duration);
      formDescriptionInfo.setFieldValue('commission', initialValues.commission);
      formDescriptionInfo.setFieldValue('discount', initialValues.discount);
      formDescriptionInfo.setFieldValue('discount_percent', initialValues.discount_percent);
      formDescriptionInfo.setFieldValue('system_name', initialValues.system_name);
      formDescriptionInfo.setFieldValue('partners_site', initialValues.partners_site);
      formDescriptionInfo.setFieldValue('shortDescription', initialValues.shortDescription);
      formDescriptionInfo.setFieldValue('fullDescription', initialValues.fullDescription);
      formDescriptionInfo.setFieldValue('priceIncludes', initialValues.priceIncludes);
      formDescriptionInfo.setFieldValue('priceNoIncludes', initialValues.priceNoIncludes);
      formDescriptionInfo.setFieldValue('price', initialValues.price);
      formDescriptionInfo.setFieldValue('addInfo', initialValues.addInfo);
      formDescriptionInfo.setFieldValue('file', initialValues.file);
      formDescriptionInfo.setFieldValue('document_template_path', initialValues.document_template_path);
    }
  }, [isResetPreSaveTour]);

  // нотификация
  useEffect(() => {
    if (isSuccess === 'GET_TOUR_DOCUMENT') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Программа тура успешно скачена' });
    }
    if (isError === 'GET_TOUR_DOCUMENT') {
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Ошибка при получении программы тура' });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (template === ToursTemplates.ONEDAY && formDescriptionInfo.values.duration != 0) {
      formDescriptionInfo.setFieldValue('duration', 0);
      dispatch(setPreSaveTourDuration(0));
      dispatch(setIsEditedTour(true));
      return;
    }
  }, [template]);
  // TODO: ПЕРЕДЕЛАТЬ
  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.ctrlKey && e.key == 'v') {
        formDescriptionInfo.setFieldValue('shortDescription', shortDescription);
        formDescriptionInfo.setFieldValue('fullDescription', fullDescription);
        formDescriptionInfo.setFieldValue('priceIncludes', priceIncludes);
        formDescriptionInfo.setFieldValue('priceNoIncludes', priceNoIncludes);
        formDescriptionInfo.setFieldValue('addInfo', addInfo);
        formDescriptionInfo.setFieldValue('monday', monday);
        formDescriptionInfo.setFieldValue('tuesday', tuesday);
        formDescriptionInfo.setFieldValue('wednesday', wednesday);
        formDescriptionInfo.setFieldValue('thursday', thursday);
        formDescriptionInfo.setFieldValue('eightday', eightday);
        formDescriptionInfo.setFieldValue('friday', friday);
        formDescriptionInfo.setFieldValue('booking_without_prepay', bookingWithoutPrepay);
        formDescriptionInfo.setFieldValue('saturday', saturday);

        formDescriptionInfo.setFieldValue('sunday', sunday);
        if (formDescriptionInfo.values.duration !== null && formDescriptionInfo.values.duration !== undefined) {
          dispatch(setPreSaveTourDuration(formDescriptionInfo.values.duration));
        }
        if (formDescriptionInfo.values.discount !== null && formDescriptionInfo.values.discount !== undefined) {
          dispatch(setPreSaveTourDiscount(formDescriptionInfo.values.discount));
        }
        if (
          formDescriptionInfo.values.discount_percent !== null &&
          formDescriptionInfo.values.discount_percent !== undefined
        ) {
          dispatch(setPreSaveTourDiscountPersent(formDescriptionInfo.values.discount_percent));
        }
        if (formDescriptionInfo.values.shortDescription) {
          dispatch(setPreSaveTourShortDescription(formDescriptionInfo.values.shortDescription));
        }
        if (formDescriptionInfo.values.system_name) {
          dispatch(setPreSaveTourShortSystemName(formDescriptionInfo.values.system_name));
        }

        dispatch(setPreSaveTourPartnersSite(String(formDescriptionInfo.values.partners_site)));

        if (formDescriptionInfo.values.fullDescription) {
          dispatch(setPreSaveTourFullDescription(formDescriptionInfo.values.fullDescription));
        }
        if (formDescriptionInfo.values.addInfo) {
          dispatch(setPreSaveTourAddInfo(formDescriptionInfo.values.addInfo));
        }
        if (formDescriptionInfo.values.addInfo) {
          dispatch(setPreSaveTourAddInfo(formDescriptionInfo.values.addInfo));
        }
        if (formDescriptionInfo.values.priceIncludes) {
          dispatch(setPreSaveTourPriceIncludes(formDescriptionInfo.values.priceIncludes));
        }
        if (formDescriptionInfo.values.priceNoIncludes) {
          dispatch(setPreSaveTourPriceNoIncludes(formDescriptionInfo.values.priceNoIncludes));
        }
        if (formDescriptionInfo.values.document_template_path) {
          dispatch(setPreSaveTourDocument(formDescriptionInfo.values.document_template_path));
        }
        if (formDescriptionInfo.values.commission) {
          dispatch(setPreSaveTourComission(formDescriptionInfo.values.commission));
        }
        if (formDescriptionInfo.values.monday) {
          dispatch(setPreSaveMonday(formDescriptionInfo.values.monday));
        }
        if (formDescriptionInfo.values.monday_title || formDescriptionInfo.values.monday_title === '') {
          dispatch(setPreSaveMondayTitle(formDescriptionInfo.values.monday_title));
        }
        if (formDescriptionInfo.values.tuesday) {
          dispatch(setPreSaveTuesday(formDescriptionInfo.values.tuesday));
        }
        if (formDescriptionInfo.values.tuesday_title || formDescriptionInfo.values.tuesday_title === '') {
          dispatch(setPreSaveTuesdayTitle(formDescriptionInfo.values.tuesday_title));
        }
        if (formDescriptionInfo.values.wednesday) {
          dispatch(setPreSaveWednesday(formDescriptionInfo.values.wednesday));
        }
        if (formDescriptionInfo.values.wednesday_title || formDescriptionInfo.values.wednesday_title === '') {
          dispatch(setPreSaveWednesdayTitle(formDescriptionInfo.values.wednesday_title));
        }
        if (formDescriptionInfo.values.thursday) {
          dispatch(setPreSaveThursday(formDescriptionInfo.values.thursday));
        }
        if (formDescriptionInfo.values.thursday_title || formDescriptionInfo.values.thursday_title === '') {
          dispatch(setPreSaveThursdayTitle(formDescriptionInfo.values.thursday_title));
        }
        if (formDescriptionInfo.values.friday) {
          dispatch(setPreSaveFriday(formDescriptionInfo.values.friday));
        }
        if (formDescriptionInfo.values.friday_title || formDescriptionInfo.values.friday_title === '') {
          dispatch(setPreSaveFridayTitle(formDescriptionInfo.values.friday_title));
        }
        if (formDescriptionInfo.values.saturday) {
          dispatch(setPreSaveSaturday(formDescriptionInfo.values.saturday));
        }
        if (formDescriptionInfo.values.saturday_title || formDescriptionInfo.values.saturday_title === '') {
          dispatch(setPreSaveSaturdayTitle(formDescriptionInfo.values.saturday_title));
        }
        if (formDescriptionInfo.values.sunday) {
          dispatch(setPreSaveSunday(formDescriptionInfo.values.sunday));
        }
        if (formDescriptionInfo.values.sunday_title || formDescriptionInfo.values.sunday_title === '') {
          dispatch(setPreSaveSundayTitle(formDescriptionInfo.values.sunday_title));
        }
        if (formDescriptionInfo.values.eightday) {
          dispatch(setPreSaveEightday(formDescriptionInfo.values.eightday));
        }
        if (formDescriptionInfo.values.eightday_title || formDescriptionInfo.values.eightday_title === '') {
          dispatch(setPreSaveEightdayTitle(formDescriptionInfo.values.eightday_title));
        }
        if (formDescriptionInfo.values.price !== null && formDescriptionInfo.values.price !== undefined) {
          dispatch(setPreSaveTourPrice(formDescriptionInfo.values.price));
        }
        if (formDescriptionInfo.values.booking_without_prepay) {
          dispatch(setPreSaveBookingPrepay(formDescriptionInfo.values.booking_without_prepay));
        }
        if (formDescriptionInfo.values.filter_price) {
          dispatch(setPreSaveTourFilterPrice(formDescriptionInfo.values.filter_price));
        }
      }
    });
  }, [isEditedTour]);

  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles).slice(0, 1);
    formDescriptionInfo.setFieldValue('file', filesFromInput);
    console.log(filesFromInput);
    dispatch(addDocument({ file: filesFromInput }));
    e.target.value = '';
  };
  return (
    <>
      <form className={styles.form}>
        {template === ToursTemplates.NO_DATE && currentTour?.id && (
          <Button
            onClick={() => {
              dispatch(getTourProgram({ tourId: Number(preSaveTour2.id) }));
            }}
            className={clsx(styles.mtmla, styles.mr)}
            transparent={true}
          >
            Программа тура <DownloadIcon />
          </Button>
        )}
        <section className={styles.selectors}>
          <div className={styles.columnGroup}>
            <Input
              label="Служебное наименование"
              {...formDescriptionInfo.getInputProps('system_name')}
              className={styles.system_name}
            />
            <Checkbox
              label="Бронь тура без оплаты"
              isChecked={bookingWithoutPrepay}
              setIsChecked={(arg) => setBookingWithoutPrepay(arg)}
            />
          </div>

          <div className={styles.durationBlock}>
            <Input
              type="number"
              label="Кол-во ночей"
              {...formDescriptionInfo.getInputProps('duration')}
              className={clsx(styles.duration, template === ToursTemplates.NO_DATE && styles.durationMin)}
              disabled={template === ToursTemplates.ONEDAY}
              iconRight={
                <>
                  {template === ToursTemplates.ONEDAY && (
                    <span
                      className={styles.helper}
                      onMouseMove={() => setViewDurationHelp(true)}
                      onMouseLeave={() => setViewDurationHelp(false)}
                    >
                      <HelperIcon />
                    </span>
                  )}
                </>
              }
            />
            {viewDurationHelp && (
              <div
                className={clsx(
                  styles.helpBlock,
                  styles.durationHelp,
                  template === ToursTemplates.NO_DATE && styles.durationHelpMin
                )}
              >
                Вы не можете редактировать кол-во ночей в однодневном туре
              </div>
            )}
          </div>
          <SelectSearch
            value={tourOperator ? { id: Number(tourOperator.id), name: tourOperator.name } : null}
            data={
              tourOperator && tourOperatorList.length === 0
                ? tourOperatorList.concat([{ id: Number(tourOperator.id), name: tourOperator.name }])
                : tourOperatorList
            }
            setSearch={setSearchOperatorVal}
            handleKey={handleTourOperatorKey}
            setValue={handleTourOperator}
            label="Туроператор"
            className={clsx(styles.selector, errorsValidateTour.operator && styles.selectorError)}
          />
          <Select
            selected={formDescriptionInfo.values.currency}
            data={['Рубль', 'Доллар', 'Евро']}
            setSelect={(a) => {
              formDescriptionInfo.setFieldValue('currency', a);
              dispatch(setPreSaveTourCurrency(a));
            }}
            label="Валюта цен"
            className={clsx(styles.selector, styles.currencies)}
          />
          <Input label="% комиссии" {...formDescriptionInfo.getInputProps('commission')} className={styles.commision} />
          {template === ToursTemplates.NO_DATE && (
            <>
              <Input
                label="Скидка %"
                {...formDescriptionInfo.getInputProps('discount_percent')}
                className={styles.discount}
                onChange={(e: any) => {
                  if (formDescriptionInfo.values.discount != 0) {
                    formDescriptionInfo.setFieldValue('discount', 0);
                  }
                  if (e.target.value.match(/^\d+$/)) {
                    formDescriptionInfo.setFieldValue('discount_percent', e.target.value);
                  } else {
                    formDescriptionInfo.setFieldValue(
                      'discount_percent',
                      e.target.value === '' ? e.target.value : formDescriptionInfo.values.discount_percent
                    );
                  }
                }}
              />
              <Input
                label="Скидка руб."
                {...formDescriptionInfo.getInputProps('discount')}
                className={styles.discount}
                onChange={(e: any) => {
                  if (formDescriptionInfo.values.discount_percent != 0) {
                    formDescriptionInfo.setFieldValue('discount_percent', 0);
                  }
                  if (e.target.value.match(/^\d+$/)) {
                    formDescriptionInfo.setFieldValue('discount', e.target.value);
                  } else {
                    formDescriptionInfo.setFieldValue(
                      'discount',
                      e.target.value === '' ? e.target.value : formDescriptionInfo.values.discount
                    );
                  }
                }}
              />
              <div className={styles.priceBlock}>
                <Input
                  type="text"
                  label="Цена тура"
                  {...formDescriptionInfo.getInputProps('price')}
                  onChange={(e: any) => {
                    if (e.target.value.match(/^\d+$/)) {
                      formDescriptionInfo.setFieldValue('price', e.target.value);
                    } else {
                      formDescriptionInfo.setFieldValue(
                        'price',
                        e.target.value === '' ? e.target.value : formDescriptionInfo.values.price
                      );
                    }
                  }}
                  className={styles.price}
                  iconRight={
                    <span
                      className={styles.helper}
                      onMouseMove={() => setViewHelp(true)}
                      onMouseLeave={() => setViewHelp(false)}
                    >
                      <HelperIcon />
                    </span>
                  }
                />
                {viewHelp && (
                  <div className={clsx(styles.helpBlock, styles.priceHelp)}>
                    Заполните поле,
                    <br />
                    если даты у тура не определены
                  </div>
                )}
              </div>
            </>
          )}
          {template != ToursTemplates.NO_DATE && currentTour?.id && (
            <Button
              onClick={() => {
                dispatch(getTourProgram({ tourId: Number(preSaveTour2.id) }));
              }}
              className={styles.mtmla}
              transparent={true}
            >
              Программа тура <DownloadIcon />
            </Button>
          )}
          <Input
            type="text"
            placeholder={formDescriptionInfo.values.priority}
            value={formDescriptionInfo.values.priority === 0 ? '' : Number(formDescriptionInfo.values.priority)}
            {...formDescriptionInfo.getInputProps('priority')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (+e.target.value <= 1000) {
                formDescriptionInfo.setFieldValue('priority', Number(e.target.value));
              } else {
                formDescriptionInfo.setFieldValue(
                  'priority',
                  e.target.value === '' ? 1 : formDescriptionInfo.values.priority
                );
              }
            }}
            className={styles.priority}
          />
        </section>
        <div className={styles.meta_tags}>
          <p>Мета теги для сайта</p>
          <Input
            label="Заголовок"
            type="text"
            name="title"
            value={meta_tags.title}
            onChange={(e) =>
              setMeta_tags((prev) => {
                return { ...prev, title: e.target.value };
              })
            }
            onBlur={() => {
              dispatch(setPreSaveDescriptionInfo({ ...preSaveTour2.descriptionInfo, meta_tags: meta_tags }));
            }}
          />
          <div className={styles.textarea}>
            <label className={styles.textareaLabel}>Описание</label>
            <textarea
              className={styles.textareaText}
              value={meta_tags.description}
              onChange={(e) =>
                setMeta_tags((prev) => {
                  return { ...prev, description: e.target.value };
                })
              }
              onBlur={() => {
                dispatch(setPreSaveDescriptionInfo({ ...preSaveTour2.descriptionInfo, meta_tags: meta_tags }));
              }}
            />
          </div>
        </div>
        <Input label="Сайт партнера" {...formDescriptionInfo.getInputProps('partners_site')} />
        <Input
          label="Цена для фильтра"
          {...formDescriptionInfo.getInputProps('filter_price')}
          onChange={(e: any) => {
            if (e.target.value.match(/^\d+$/)) {
              formDescriptionInfo.setFieldValue('filter_price', e.target.value);
            } else {
              formDescriptionInfo.setFieldValue(
                'filter_price',
                e.target.value === '' ? e.target.value : formDescriptionInfo.values.filter_price
              );
            }
          }}
        />
        <div className={styles.descriptions}>
          <h4 className={styles.subTitle}>Краткое описание (маршрут)</h4>
          <TextEditor
            data={shortDescription}
            setData={setShortDescription}
            placeholder="Добавьте описание"
            className={styles.shortDesc}
          />
          <h4 className={styles.subTitle}>Полное описание</h4>
          <TextEditor
            data={fullDescription}
            setData={setFullDescription}
            placeholder="Добавьте описание"
            className={styles.fullDesc}
          />
        </div>
        {template != ToursTemplates.EVERYDAY && (
          <TourProgram days={tourProgram} setInfo={handleTourProgram} className={styles.tourProgram} />
        )}
        {template === ToursTemplates.EVERYDAY && (
          <section className={styles.tourProgram}>
            <h3 className={styles.subTitle}>Программа тура</h3>
            <div className={styles.day}>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.monday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('monday_title', e.target.value);
                  }}
                />
                <button
                  type="button"
                  className={styles.remove}
                  onClick={() => formDescriptionInfo.setFieldValue('monday_title', '')}
                >
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={monday}
                setData={setMonday}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.tuesday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('tuesday_title', e.target.value);
                  }}
                />
                <button
                  className={styles.remove}
                  onClick={() => formDescriptionInfo.setFieldValue('tuesday_title', '')}
                >
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={tuesday}
                setData={(x) => formDescriptionInfo.setFieldValue('tuesday', x)}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.wednesday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('wednesday_title', e.target.value);
                  }}
                />{' '}
                <button
                  className={styles.remove}
                  onClick={() => formDescriptionInfo.setFieldValue('wednesday_title', '')}
                >
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={wednesday}
                setData={setWednesday}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.thursday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('thursday_title', e.target.value);
                  }}
                />{' '}
                <button
                  className={styles.remove}
                  onClick={() => formDescriptionInfo.setFieldValue('thursday_title', '')}
                >
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={thursday}
                setData={setThursday}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.friday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('friday_title', e.target.value);
                  }}
                />
                <button className={styles.remove} onClick={() => formDescriptionInfo.setFieldValue('friday_title', '')}>
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={friday}
                setData={setFriday}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.saturday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('saturday_title', e.target.value);
                  }}
                />
                <button
                  className={styles.remove}
                  onClick={() => formDescriptionInfo.setFieldValue('saturday_title', '')}
                >
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={saturday}
                setData={setSaturday}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.sunday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('sunday_title', e.target.value);
                  }}
                />
                <button className={styles.remove} onClick={() => formDescriptionInfo.setFieldValue('sunday_title', '')}>
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={sunday}
                setData={setSunday}
                customModules={customModule}
              />
            </div>
            <div className={styles.day}>
              <h3 className={styles.subTitle}>Программа тура</h3>
              <div className={styles.headerWrapper}>
                <input
                  className={clsx(styles.titleProgram)}
                  value={formDescriptionInfo.values.eightday_title}
                  onChange={(e) => {
                    formDescriptionInfo.setFieldValue('eightday_title', e.target.value);
                  }}
                />
                <button
                  className={styles.remove}
                  onClick={() => formDescriptionInfo.setFieldValue('eightday_title', '')}
                >
                  <RemoveIcon width={15} height={15} />
                </button>
              </div>
              <TextEditor
                placeholder="Добавьте описание"
                className={styles.fullDesc}
                data={eightday}
                setData={setEightday}
                customModules={customModule}
              />
            </div>
          </section>
        )}
        {template === ToursTemplates.NO_DATE && (
          <AddDescription
            columns={addDescColumns}
            setColumns={handleAddColumns}
            title={'Стоимость для групп'}
            titleDescription={titleDesc}
            setTitleDescription={handleAddTitleDesc}
            items={addDesc}
            setAddService={handleAddDesc}
          />
        )}
        <h4 className={styles.subTitle}>В стоимость входит</h4>
        <TextEditor
          data={priceIncludes}
          setData={setPriceIncludes}
          placeholder="Добавьте описание"
          className={styles.shortDesc}
        />
        <h4 className={styles.subTitle}>В стоимость НЕ входит</h4>
        <TextEditor
          data={priceNoIncludes}
          setData={setPriceNoIncludes}
          placeholder="Добавьте описание"
          className={styles.shortDesc}
        />
        <AddService
          columns={columnsAddService}
          title={'Доп. услуги'}
          items={addServices}
          setAddService={handleAddService}
        />

        <AddSales
          columns={columnsAddDiscount}
          title={'Скидка'}
          items={addDiscounts}
          setAddService={handleAddDiscounts}
        />

        <h4 className={styles.subTitle}>Дополнительная информация</h4>
        <TextEditor data={addInfo} setData={setaddInfo} placeholder="Добавьте описание" className={styles.shortDesc} />
        <div className={styles.applicationBlock}>
          <h2>Приложение №3 - памятка к туру</h2>
          <div className={styles.addFileBlock}>
            <span>Загрузить :</span>
            <label htmlFor="file" className={styles.addFileLabel}>
              <img src={upload} width={14} height={14} /> Нажмите, чтобы загрузить
            </label>
            <input
              type="file"
              id="file"
              name="file"
              accept=".doc,.docx"
              onChange={(e) => handleAddFiles(e)}
              className={styles.addFile}
            />
          </div>
          {formDescriptionInfo.values.file &&
            formDescriptionInfo.values.file.map((file: File) => (
              <ImageFile
                key={file.name}
                preview={URL.createObjectURL(file as Blob)}
                name={file.name}
                size={file.size}
                canDownload
                type={file.type}
                deleteFile={() => {
                  dispatch(setPreSaveTourDocument(''));
                  formDescriptionInfo.setFieldValue('document_template_path', '');
                  formDescriptionInfo.setFieldValue('file', undefined);
                }}
              />
            ))}
        </div>
      </form>
    </>
  );
};
