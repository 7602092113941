import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useForm } from '@mantine/form';
import { DatePickerInput, DatesProvider } from '@mantine/dates';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Select } from 'ui/Select';
import { DateIcon } from 'components/icons';
import { Input } from 'ui/Input';
import { Checkbox } from 'ui/Checkbox';
import { AccordionFeature } from 'ui/Accordion';
import { Tourists } from './Toursits';
import { Accommodation } from './Accomodation';
import { ExtraNight } from './ExtraNight';
import { AdditionalServices } from './AdditionalServices';
import { Sales } from './Sales';
import { Transfers } from './Transfers';
import { TextEditor } from 'ui/TextEditor';
import { TourInfo, TourOperator } from 'pages/Orders/Order.types';
import { setPreSaveOrder } from 'store/entities/orders/ordersSlice';
import {
  getAllToursForSearch,
  getTour,
  getTourDatesList,
  getTourDatesPlacements,
  getTourHotels,
  getTourNights,
} from 'store/action-creators/tours';
import { getDate } from 'core/utils/getDate';
import { SelectSearch } from 'ui/SelectSearch';
import { TourInfoTabProps } from './TourInfoTab.types';

import styles from './TourInfoTab.module.scss';
import {
  accomOneInd,
  accomodationColumns,
  accomodationEveryDayColumns,
  accomodationIndColumns,
  accomodationOneColumns,
  cruizeColumns,
  hotelsTransferColumns,
  passColumns,
} from './constants';
import { getTourOperatorsForSearch } from 'store/action-creators/partners';
import { ToursTemplates } from 'core/constants/enums';
import { getTemplateToUI } from 'core/utils/getTemplate';
import { AccommodationEveryDay } from './AccomodationEveryDay';
import { PassPlace } from './PassPlace';
import { InputDate } from 'ui/InputDate';

export const TourInformation: FC<TourInfoTabProps> = ({ tourInfo, setDirty, isCanEdit }) => {
  const dispatch = useAppDispatch();
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const { tourOperatorsForSearch } = useAppSelector((state) => state.partners);
  // тур с детальной иформацией
  const tourById = useAppSelector((state) => state.tours.selectedTour);
  const tourDateList = useAppSelector((state) => state.tours.tourDateList);
  const toursForSearch = useAppSelector((state) => state.tours.toursForSearch);
  //контрагенты
  const touristsData = useAppSelector((state) => state.partners.partners);
  const placements = useAppSelector((state) => state.tours.tourDatePlacements);
  const nights = useAppSelector((state) => state.tours.tourDateNight);
  // даты тура
  const tourDates = tourDateList.length
    ? tourDateList.map((item) => {
        return {
          id: item['@id'],
          value: getDate(new Date(item.start)) + '-' + getDate(new Date(item.ending)),
        };
      })
    : [];

  const [tourName, setTourName] = useState<{ id: number; name: string } | null>(
    preSaveOrder.tourInfo?.tour
      ? {
          id: preSaveOrder.tourInfo?.tour.id,
          name: preSaveOrder.tourInfo?.tour.title,
        }
      : null
  );
  const [tourDate, setTourDate] = useState<string>(preSaveOrder.tourInfo?.date || '');
  const [individualDate, setindividualDate] = useState<any>();
  // упрощенный объект тура с датой тура и стоимостью
  const tourByIdShort = tourDateList.length
    ? tourDateList.map((item) => {
        return {
          date: getDate(new Date(item.start)) + '-' + getDate(new Date(item.ending)),
          basePrice: item.base_price,
        };
      })
    : [];
  const basePrice =
    tourByIdShort.length > 0 ? tourByIdShort.find((el) => el.date === tourDate) : { basePrice: tourById.price };
  const [isIndivilualTour, setIsIndividualTour] = useState(preSaveOrder.tourInfo?.individual || false);
  const [isInternationalPassport, setIsInternationalPassport] = useState(
    preSaveOrder.tourInfo?.internationalPassport || false
  );
  const [tourOperator, setTourOperator] = useState<TourOperator | null>(
    preSaveOrder.tourInfo?.tour_operator_contact || null
  );
  const [isPrintAcvarel, setisPrintAcvarel] = useState(preSaveOrder.tourInfo?.show_akvarel || false);
  const [showInPass, setShowInPass] = useState(preSaveOrder.tourInfo?.show_in_pass || false);
  const [isAccommodation, setIsAccommodation] = useState(
    preSaveOrder.tourInfo && preSaveOrder.tourInfo?.hotels.length > 0 ? true : false
  );
  const [isPassPlace, setIsPassPlace] = useState(
    preSaveOrder.tourInfo && preSaveOrder.tourInfo?.pass_placements_array?.length > 0 ? true : false
  );
  const [isExtraNight, setIsExtraNight] = useState(
    preSaveOrder.tourInfo && preSaveOrder.tourInfo?.extraNights.length > 0 ? true : false
  );
  const [allData, setAllData] = useState<TourInfo>();
  const [indexOfDate, setIndexOfDate] = useState<number>();
  const [isHotelTransfer, setisHotelTransfer] = useState(preSaveOrder.tourInfo?.isHotelTransfer || false);
  const [isCruize, setisCruize] = useState(preSaveOrder.tourInfo?.isCruize || false);

  const [pass_departure, setPass_departure] = useState<{
    date: string;
    place_departure: string;
    place_arrival: string;
    type: string;
  }>({
    date: '',
    place_departure: '',
    place_arrival: '',
    type: '',
  });

  //начальные значения для стандартных полей формы
  const initialValues: TourInfo = {
    ...{
      tour: preSaveOrder.tourInfo?.tour || null,
      tour_name: preSaveOrder.tourInfo?.tour_name,
      date: preSaveOrder.tourInfo?.date || null,
      adults: preSaveOrder.tourInfo?.adults || 0,
      children: preSaveOrder.tourInfo?.children || 0,
      operatorApplicationNo: preSaveOrder.tourInfo?.operatorApplicationNo || '',
      reserve_system_name: preSaveOrder.tourInfo?.reserve_system_name || '',
      individual: preSaveOrder.tourInfo?.individual || false,
      internationalPassport: preSaveOrder.tourInfo?.internationalPassport || false,
      tour_operator_contact: preSaveOrder.tourInfo?.tour_operator_contact || null,
      tourists: preSaveOrder.tourInfo?.tourists || [],
      isAccommodation: preSaveOrder.tourInfo?.isAccommodation || false,
      isExtraNight: preSaveOrder.tourInfo?.isExtraNight || false,
      isHotelTransfer: preSaveOrder.tourInfo?.isHotelTransfer || false,
      isCruize: preSaveOrder.tourInfo?.isCruize || false,
      hotels: preSaveOrder.tourInfo?.hotels || [],
      hotelsTrans: preSaveOrder.tourInfo?.hotelsTrans || [],
      extraService: preSaveOrder.tourInfo?.extraService || [],
      extraNights: preSaveOrder.tourInfo?.extraNights || [],
      sales: preSaveOrder.tourInfo?.sales || [],
      transfers: preSaveOrder.tourInfo?.transfers || [],
      fullDescription: preSaveOrder.tourInfo?.fullDescription || '',
      show_akvarel: preSaveOrder.tourInfo?.show_akvarel || false,
      show_in_pass: preSaveOrder.tourInfo?.show_in_pass || false,
      pass_placements_array: preSaveOrder.tourInfo?.pass_placements_array || [],
      pass_tour_dates_array: preSaveOrder.tourInfo?.pass_tour_dates_array || [],
      contract_date: preSaveOrder.tourInfo?.contract_date || '',
      contract_number: preSaveOrder.tourInfo?.contract_number || '',
      contract_sum: preSaveOrder.tourInfo?.contract_sum || '',
    },
    ...tourInfo,
  };
  console.log(preSaveOrder.tourInfo);
  const formTourInfo = useForm({
    initialValues,
  });

  useEffect(() => {
    setAllData({ ...allData, ...formTourInfo.values });
  }, [formTourInfo.values]);

  //начальные значения для управляемых полей

  useEffect(() => {
    if (tourDate) {
      const individualDates = tourDate.split('-').map((item) => {
        return new Date(moment(item, 'DD.MM.YYYY').toDate());
      });
      setindividualDate(individualDates);
    }
  }, []);

  useEffect(() => {
    if (isHotelTransfer || isCruize) return;
    if (tourDate) {
      const selectedDate = tourDates.find((el) => el.value === tourDate);
      if (placements.length === 0) {
        dispatch(getTourDatesPlacements({ tour_date: String(selectedDate?.id) }));
      }
      if (nights.length === 0) {
        dispatch(getTourNights({ tour_date: String(selectedDate?.id) }));
      }
    }
  }, [tourDate]);

  //предсохранение всех данных в стейте и сбор данных всей вкладки для отправки в родительский компонент
  useEffect(() => {
    if (allData) {
      dispatch(
        setPreSaveOrder({
          ...preSaveOrder,
          tourInfo: {
            ...allData,
            base_price: basePrice?.basePrice ? basePrice?.basePrice?.toString() : '0',
            tour_operator_contact:
              preSaveOrder.tourInfo &&
              preSaveOrder.tourInfo?.tour_operator_contact?.id === formTourInfo.values.tour_operator_contact?.id
                ? preSaveOrder.tourInfo?.tour_operator_contact
                : formTourInfo.values.tour_operator_contact,
          },
        })
      );
    }
  }, [allData]);
  useEffect(() => {
    if (isHotelTransfer || isCruize) return;
    if (tourById.id === tourName?.id) return;
    if (tourName) {
      formTourInfo.setFieldValue('tour', { id: tourName?.id, title: tourName?.name });
      dispatch(getTour({ id: tourName.id.toString() }));
      dispatch(getTourDatesList({ id: `/api/v1/tours/${tourName.id}` }));
    }
  }, [tourName]);
  useEffect(() => {
    if (tourOperator) {
      formTourInfo.setFieldValue('tour_operator_contact', tourOperator);
    }
  }, [tourOperator]);
  useEffect(() => {
    if (
      getTemplateToUI(tourById.template) === ToursTemplates.EVERYDAY ||
      isIndivilualTour ||
      (tourName?.name && tourDateList.length === 0)
    ) {
      if (individualDate && individualDate[0] !== undefined && individualDate[1] !== null) {
        formTourInfo.setFieldValue(
          'date',
          `${getDate(new Date(individualDate[0]))}-${getDate(new Date(individualDate[1]))}`
        );
      }
    }
  }, [tourById.template, individualDate, isIndivilualTour]);

  function updDateIndex() {
    const newIndex = tourDates.findIndex((item) => item.value === tourDate);
    setIndexOfDate(newIndex === -1 ? undefined : newIndex);
  }

  // обновление индекса даты - чтобы избежать ошибки
  useEffect(() => {
    if (tourDates.length > 0) {
      updDateIndex();
    }
  }, [tourDates]);

  const [searchVal, setSearchVal] = useState('');
  const [searchOperatorVal, setSearchOperatorVal] = useState('');
  const tourOperatorList = useMemo(
    () =>
      tourOperatorsForSearch.length > 0 && searchOperatorVal != ''
        ? tourOperatorsForSearch
            .filter((el) => el.name?.toLowerCase().includes(searchOperatorVal.toLowerCase()))
            .map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            })
        : [],
    [tourOperatorsForSearch, searchOperatorVal]
  );

  const toursList = useMemo(
    () =>
      toursForSearch.length > 0 && searchVal != ''
        ? toursForSearch
            .filter(
              (el) =>
                el.system_name?.toLowerCase().includes(searchVal.toLowerCase()) ||
                el.name?.toLowerCase().includes(searchVal.toLowerCase())
            )
            .map((el) => {
              return {
                id: el.id,
                name: el.name,
                subName: el.system_name ? el.system_name : '-',
              };
            })
        : [],
    [toursForSearch, searchVal]
  );
  const handleKey = () => {
    if (searchVal != '') {
      dispatch(
        getAllToursForSearch({
          search: searchVal,
          properties: ['id', 'name', 'system_name'],
          itemsPerPage: 100,
        })
      );
      return;
    }
  };
  const handleTourOperatorKey = () => {
    if (searchOperatorVal != '') {
      dispatch(
        getTourOperatorsForSearch({
          search: searchOperatorVal,
          properties: ['id', 'full_name', 'requisites'],
          type: '/api/v1/contact-types/2',
          itemsPerPage: 100,
        })
      );
      return;
    }
  };
  // установка даты поездки при смене тура
  const idRef = useRef<number>();
  useEffect(() => {
    if (
      preSaveOrder.tourInfo?.tour?.id &&
      idRef.current &&
      JSON.stringify(idRef.current) !== JSON.stringify(preSaveOrder.tourInfo?.tour?.id)
    ) {
      const firstDateFromTrips = tourDateList.map(
        (item) => getDate(new Date(item.start)) + '-' + getDate(new Date(item.ending))
      );
      if (firstDateFromTrips.length === 0) {
        setTourDate('');
      } else {
        setTourDate(firstDateFromTrips[0]);
      }
    }
    idRef.current = preSaveOrder.tourInfo?.tour?.id;
  }, [tourById?.id]);

  useEffect(() => {
    if (isHotelTransfer || isCruize) return;
    if (individualDate) {
      if (tourById.template === 'everyday') {
        dispatch(
          getTourHotels({
            page: 1,
            tour: tourById['@id'],
            dateFrom: getDate(new Date(individualDate[1])),
            dateTo: getDate(new Date(individualDate[0])),
            itemsPerPage: 100,
          })
        );
      }
    }
  }, [individualDate]);

  useEffect(() => {
    if (preSaveOrder.tourInfo?.pass_tour_dates_array && preSaveOrder.tourInfo?.pass_tour_dates_array.length > 0) {
      if (pass_departure.date === '') {
        setPass_departure(preSaveOrder.tourInfo?.pass_tour_dates_array[0]);
      }
    }
  }, [pass_departure]);

  return (
    <>
      <div className={styles.form}>
        <section className={styles.tourSection}>
          <div className={styles.tour}>
            {!isHotelTransfer && !isCruize && (
              <SelectSearch
                value={tourInfo && tourInfo.tour ? { id: tourInfo.tour.id, name: tourInfo.tour.title } : tourName}
                disabled={!isCanEdit}
                data={
                  tourInfo && tourInfo.tour && tourInfo.tour.title != ''
                    ? toursList.concat([
                        {
                          id: tourInfo.tour.id,
                          name: tourInfo.tour.title,
                          subName: tourInfo.tour.system_name ? tourInfo.tour.system_name : '-',
                        },
                      ])
                    : formTourInfo.values.tour != null && formTourInfo.values.tour.title != ''
                    ? toursList.concat([
                        {
                          id: formTourInfo.values.tour.id,
                          name: formTourInfo.values.tour.title,
                          subName: formTourInfo.values.tour.system_name ? formTourInfo.values.tour.system_name : '-',
                        },
                      ])
                    : toursList
                }
                onBlur={() => {
                  if (tourName != null) {
                    setDirty();
                  }
                }}
                setSearch={setSearchVal}
                handleKey={handleKey}
                setValue={(a) => {
                  setTourName(a);
                }}
                label="Тур"
                className={styles.tourName}
              />
            )}
            {!isHotelTransfer && !isCruize && (
              <div style={{ width: '326px', height: '112px', padding: '16px 0' }}>
                {tourDates.length > 0 &&
                  !isIndivilualTour &&
                  getTemplateToUI(tourById.template) !== ToursTemplates.EVERYDAY && (
                    <Select
                      selected={tourDate}
                      data={tourDates.map((el) => el.value)}
                      onBlur={setDirty}
                      disabled={!isCanEdit}
                      setSelect={(a) => {
                        formTourInfo.setFieldValue('date', a);
                        setTourDate(a);
                        updDateIndex();
                      }}
                      label="Дата тура"
                      className={styles.tourDate}
                      icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
                      uploaded={tourDates.length > 0}
                    />
                  )}
                {(isIndivilualTour ||
                  (tourName?.name != '' && tourDates.length === 0) ||
                  getTemplateToUI(tourById.template) === ToursTemplates.EVERYDAY) && (
                  <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
                    <DatePickerInput
                      type="range"
                      allowSingleDateInRange={true}
                      valueFormat="DD.MM.YYYY "
                      label="Дата тура"
                      value={individualDate}
                      onBlur={setDirty}
                      disabled={!isCanEdit}
                      onChange={(date) => {
                        setindividualDate(date);
                      }}
                      maw={330}
                      icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
                      className={styles.select}
                      // rightSection={<DownContourIcon width={8} height={4} stroke="#5B6572" />}
                      styles={{
                        rightSection: { pointerEvents: 'none' },
                        label: {
                          fontSize: '15px',
                          color: '#737b87',
                          marginBottom: '10px',
                          fontFamily: 'Museo Sans Cyrl',
                        },
                        input: {
                          width: '100%',
                          height: '40px',
                          marginRight: '16px',
                          marginBottom: '10px',
                          padding: ' 10px 16px',
                          border: '1px solid #f5f6fa',
                          borderRadius: '8px 8px 8px 8px',
                          background: '#F5F6FA',
                          fontFamily: 'Museo Sans Cyrl',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '19px',
                          color: '#263B53',
                        },
                        icon: {
                          bottom: '4px',
                        },
                      }}
                    />
                  </DatesProvider>
                )}
              </div>
            )}
            {isCruize && (
              <>
                <Input
                  type="text"
                  name="Название круиза"
                  label="Название круиза"
                  placeholder=""
                  disabled={!isCanEdit}
                  onBlur={setDirty}
                  {...formTourInfo.getInputProps('tour_name')}
                  className={styles.adult}
                  background={'#F5F6FA'}
                />
                <SelectSearch
                  value={
                    tourOperator
                      ? { id: Number(tourOperator.id), name: tourOperator.name }
                      : tourInfo?.tour_operator_contact
                      ? {
                          id: Number(preSaveOrder.tourInfo?.tour_operator_contact?.id),
                          name: String(preSaveOrder.tourInfo?.tour_operator_contact?.name),
                        }
                      : null
                  }
                  disabled={!isCanEdit}
                  data={
                    tourInfo && tourInfo.tour_operator_contact && tourInfo.tour_operator_contact.name != ''
                      ? tourOperatorList.concat([
                          {
                            id: Number(tourInfo.tour_operator_contact.id),
                            name: String(tourInfo.tour_operator_contact.name),
                          },
                        ])
                      : formTourInfo.values.tour_operator_contact != null &&
                        formTourInfo.values.tour_operator_contact.name != ''
                      ? tourOperatorList.concat([
                          {
                            id: Number(formTourInfo.values.tour_operator_contact.id),
                            name: String(formTourInfo.values.tour_operator_contact.name),
                          },
                        ])
                      : tourOperatorList
                  }
                  setSearch={setSearchOperatorVal}
                  handleKey={handleTourOperatorKey}
                  setValue={(a) => {
                    setTourOperator(a);
                    formTourInfo.setFieldValue('tour_operator_contact', a);
                  }}
                  label="Туроператор"
                  className={styles.tourOperator}
                  onBlur={() => {
                    if (formTourInfo.values.tour_operator_contact != null) {
                      setDirty();
                    }
                  }}
                />
              </>
            )}
            <Input
              type="number"
              name="Кол-во взрослых"
              label="Кол-во взрослых"
              placeholder=""
              disabled={!isCanEdit}
              onBlur={setDirty}
              {...formTourInfo.getInputProps('adults')}
              className={styles.adult}
              background={'#F5F6FA'}
            />
            <Input
              type="number"
              name="Кол-во детей"
              label="Кол-во детей"
              placeholder=""
              disabled={!isCanEdit}
              onBlur={setDirty}
              {...formTourInfo.getInputProps('children')}
              className={styles.child}
              background={'#F5F6FA'}
            />
            {(isHotelTransfer || isCruize) && (
              <Input
                type="text"
                name="Система бронирования"
                label="Система бронирования"
                {...formTourInfo.getInputProps('reserve_system_name')}
                placeholder=""
                onBlur={setDirty}
                className={styles.systemOrder}
              />
            )}
          </div>
          <div className={styles.checkboxes}>
            <Checkbox
              label="Отель и трансфер"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBlur={setDirty}
              disabled={!isCanEdit}
              isChecked={isHotelTransfer}
              setIsChecked={(arg) => {
                setisHotelTransfer(arg);
                formTourInfo.setFieldValue('isHotelTransfer', arg);
                setisCruize(false);
                formTourInfo.setFieldValue('isCruize', false);
              }}
            />
            <Checkbox
              label="Круиз"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBlur={setDirty}
              disabled={!isCanEdit}
              isChecked={isCruize}
              setIsChecked={(arg) => {
                setisCruize(arg);
                formTourInfo.setFieldValue('isCruize', arg);
                formTourInfo.setFieldValue('isHotelTransfer', false);
                setisHotelTransfer(false);
              }}
            />
            <Checkbox
              label="Индивидуальный тур"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBlur={setDirty}
              isChecked={isIndivilualTour}
              disabled={!isCanEdit}
              setIsChecked={(arg) => {
                setIsIndividualTour(arg);
                formTourInfo.setFieldValue('individual', arg);
                setindividualDate([]);
                formTourInfo.setFieldValue('date', null);
              }}
            />
            <Checkbox
              label="Загранпаспорт"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBlur={setDirty}
              disabled={!isCanEdit}
              isChecked={isInternationalPassport}
              setIsChecked={(arg) => {
                formTourInfo.setFieldValue('internationalPassport', arg);
                setIsInternationalPassport(arg);
              }}
            />
            <Checkbox
              label="Выводить Акварель"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBlur={setDirty}
              isChecked={isPrintAcvarel}
              setIsChecked={(arg) => {
                formTourInfo.setFieldValue('show_akvarel', arg);
                setisPrintAcvarel(arg);
              }}
              disabled={tourById.tour_operator_contact === null || !isCanEdit}
            />
            <Checkbox
              label="Выгружать в путевку"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBlur={setDirty}
              isChecked={showInPass}
              setIsChecked={(arg) => {
                formTourInfo.setFieldValue('show_in_pass', arg);
                setShowInPass(arg);
              }}
              disabled={!isCanEdit}
            />
          </div>
          <div className={styles.contracts}>
            <InputDate
              label="Дата выдачи"
              className={styles.input_date}
              onBlur={setDirty}
              clearable
              icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
              date={formTourInfo.values.contract_date ? new Date(formTourInfo.values.contract_date) : undefined}
              setDate={(a) => {
                formTourInfo.setFieldValue('contract_date', a === null ? null : a);

                setDirty();
              }}
            />
            <Input
              type="text"
              name="Номер заявки"
              label="Номер договора"
              disabled={!isCanEdit}
              placeholder=""
              {...formTourInfo.getInputProps('contract_number')}
              onBlur={setDirty}
              className={styles.operator}
            />
            <Input
              type="text"
              name="Сумма договора"
              label="Сумма договора"
              disabled={!isCanEdit}
              placeholder=""
              {...formTourInfo.getInputProps('contract_sum')}
              onBlur={setDirty}
              className={styles.operator}
            />

            <Input
              type="text"
              name="Номер заявки"
              label="№ заявки оператора"
              disabled={!isCanEdit}
              placeholder=""
              {...formTourInfo.getInputProps('operatorApplicationNo')}
              onBlur={setDirty}
              className={styles.operator}
            />
          </div>
        </section>
        <section className={styles.accordiontSection}>
          <AccordionFeature
            title="Даты отправления"
            is_opened={formTourInfo.values.pass_tour_dates_array?.length > 0 ? true : false}
            item={
              <div className={styles.date_departure}>
                <div className={styles.departure_input}>
                  <label>Населенный пункт отправления</label>
                  <input
                    type="text"
                    className={styles.departure}
                    disabled={!isCanEdit}
                    onBlur={setDirty}
                    value={pass_departure.place_departure}
                    onChange={(e) => {
                      const data = {
                        ...pass_departure,
                        date: tourDate,
                        place_departure: e.target.value,
                      };
                      setPass_departure(data);
                      formTourInfo.setFieldValue('pass_tour_dates_array', [data]);
                    }}
                  />
                </div>
                <div className={styles.departure_input}>
                  <label>Населенный пункт прибытия</label>
                  <input
                    type="text"
                    className={styles.departure}
                    value={pass_departure.place_arrival}
                    onChange={(e) => {
                      const data = {
                        ...pass_departure,
                        date: tourDate,
                        place_arrival: e.target.value,
                      };
                      setPass_departure(data);
                      formTourInfo.setFieldValue('pass_tour_dates_array', [data]);
                    }}
                    disabled={!isCanEdit}
                    onBlur={setDirty}
                  />
                </div>
                <Select
                  selected={pass_departure.type}
                  data={['Лечение', 'Мероприятие', 'Отдых', 'Конференция']}
                  onBlur={setDirty}
                  disabled={!isCanEdit}
                  setSelect={(a) => {
                    const data = {
                      ...pass_departure,
                      date: tourDate,
                      type: a,
                    };
                    setPass_departure(data);
                    formTourInfo.setFieldValue('pass_tour_dates_array', [data]);
                  }}
                  label="Тип поездки"
                  className={styles.type_departure}
                />
              </div>
            }
          />
        </section>
        <section className={styles.accordiontSection}>
          <AccordionFeature
            title="Туристы"
            is_opened={formTourInfo.values.tourists.length > 0 ? true : false}
            item={
              <Tourists
                tourists={touristsData.map((item) => ({ id: item.id, name: item.name ? item.name : '' }))}
                selectedTourists={formTourInfo.values.tourists}
                setSelectedTourists={(x) => {
                  formTourInfo.setFieldValue('tourists', x);
                }}
                setDirty={setDirty}
                isCanEdit={isCanEdit}
              />
            }
          />
        </section>

        <section className={styles.section}>
          <div className={styles.accommodation}>
            <Checkbox
              label={isHotelTransfer ? 'Отель' : isCruize ? 'Продажа круизов' : 'Размещение'}
              isChecked={isAccommodation}
              setIsChecked={(arg) => {
                setIsAccommodation(arg);
                formTourInfo.setFieldValue('isAccommodation', arg);
              }}
            />
          </div>

          {isAccommodation && (
            <>
              {getTemplateToUI(tourById.template) === ToursTemplates.EVERYDAY ? (
                <AccommodationEveryDay
                  columns={accomodationEveryDayColumns}
                  tour={tourById}
                  dates={individualDate && individualDate.length > 0 ? [individualDate[0], individualDate[1]] : []}
                  selectedAccomodation={formTourInfo.values.hotels}
                  setSelectedAccomodation={(x) => {
                    formTourInfo.setFieldValue('hotels', x);
                  }}
                  selectedTourists={formTourInfo.values.tourists}
                  setDirty={setDirty}
                  isCanEdit={isCanEdit}
                />
              ) : (
                <Accommodation
                  columns={
                    isHotelTransfer
                      ? hotelsTransferColumns
                      : isCruize
                      ? cruizeColumns
                      : (isIndivilualTour && getTemplateToUI(tourById.template) !== ToursTemplates.ONEDAY) ||
                        (tourById?.name != '' && tourDateList.length === 0)
                      ? accomodationIndColumns
                      : getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY && isIndivilualTour
                      ? accomOneInd
                      : getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY
                      ? accomodationOneColumns
                      : accomodationColumns
                  }
                  tour={tourDateList}
                  dateIndex={indexOfDate}
                  selectedAccomodation={formTourInfo.values.hotels}
                  setSelectedAccomodation={(x) => {
                    formTourInfo.setFieldValue('hotels', x);
                  }}
                  selectedTourists={formTourInfo.values.tourists}
                  setDirty={setDirty}
                  isCanEdit={isCanEdit}
                />
              )}
            </>
          )}
        </section>

        <section className={styles.section}>
          <div className={styles.accommodation}>
            <Checkbox
              label={'Размещение для эл.путевки'}
              isChecked={isPassPlace}
              setIsChecked={(arg) => {
                setIsPassPlace(arg);
                formTourInfo.setFieldValue('isPassPlace', arg);
              }}
            />
          </div>

          {isPassPlace && (
            <>
              <PassPlace
                columns={passColumns}
                dateIndex={indexOfDate}
                selectedAccomodation={formTourInfo.values.pass_placements_array}
                setSelectedAccomodation={(x) => {
                  formTourInfo.setFieldValue('pass_placements_array', x);
                }}
                selectedTourists={formTourInfo.values.tourists}
                setDirty={setDirty}
                isCanEdit={isCanEdit}
              />
            </>
          )}
        </section>

        {!isCruize && !isHotelTransfer && (
          <section className={styles.section}>
            <div className={styles.accommodation}>
              <Checkbox
                label="Доп. ночи"
                isChecked={isExtraNight}
                setIsChecked={(arg) => {
                  setIsExtraNight(arg);
                  formTourInfo.setFieldValue('isExtraNight', arg);
                }}
              />
            </div>

            {isExtraNight && (
              <ExtraNight
                tour={tourDateList}
                dateIndex={indexOfDate}
                selectedExtraNight={formTourInfo.values.extraNights}
                selectedTourists={formTourInfo.values.tourists}
                setSelectedExtraNight={(x) => {
                  formTourInfo.setFieldValue('extraNights', x);
                }}
                setDirty={setDirty}
                isCanEdit={isCanEdit}
              />
            )}
          </section>
        )}
        <section className={styles.section}>
          <AccordionFeature
            title="Доп. услуги"
            is_opened={formTourInfo.values.extraService.length > 0 ? true : false}
            item={
              <AdditionalServices
                tour={tourById}
                selectedAdditionalServices={formTourInfo.values.extraService}
                setSelectedAdditionalServices={(x) => {
                  formTourInfo.setFieldValue('extraService', x);
                }}
                setDirty={setDirty}
                isCanEdit={isCanEdit}
              />
            }
          />
        </section>
        <section className={styles.section}>
          <AccordionFeature
            title="Скидки"
            is_opened={formTourInfo.values.sales.length > 0 ? true : false}
            item={
              <Sales
                tour={tourById}
                selectedSales={formTourInfo.values.sales}
                setSelectedSales={(x) => {
                  formTourInfo.setFieldValue('sales', x);
                }}
                setDirty={setDirty}
                isCanEdit={isCanEdit}
              />
            }
          />
        </section>
        <section className={styles.section}>
          <AccordionFeature
            title="Трансфер"
            is_opened={formTourInfo.values.transfers.length > 0 ? true : false}
            item={
              <Transfers
                tour={preSaveOrder.tourInfo?.tour}
                selectedTransfers={formTourInfo.values.transfers}
                setSelectedTransfers={(x) => {
                  formTourInfo.setFieldValue('transfers', x);
                }}
                setDirty={setDirty}
                isCanEdit={isCanEdit}
              />
            }
          />
        </section>
        <section>
          <h4 className={styles.subTitle}>Перевозка</h4>
          <TextEditor
            data={formTourInfo.values.fullDescription}
            setData={(x) => {
              if (formTourInfo.values.fullDescription != '' && formTourInfo.values.fullDescription != x) {
                setDirty();
              }
              formTourInfo.setFieldValue('fullDescription', x);
            }}
            placeholder="Перевозка"
            className={styles.desc}
            isCanEdit={!isCanEdit}
          />
        </section>
      </div>
    </>
  );
};
